import React from 'react';
import Grid from '@material-ui/core/Grid';

class ClientAndProductInput extends React.Component {
    render() {
        return (
            <div>
                <Grid container spacing={2}>

                    <Grid item xs={6} sm={4}>
                        <label for="client">Client Name
                        {this.props.disabled ? "" :
                         <span style={{ color: "red" }}>*</span>} 
                         :
                          {this.props.disabled ?
                           <b>{this.props.clientName} </b>
                            : ""}
                        </label>
                        {this.props.disabled ? "" :
                        <>
                         <input 
                            required 
                            type="text"
                            placeholder="Client Name" className="form-control" 
                            id="clientName" 
                            value={this.props.clientName} 
                            onChange={this.props.onProjectandClientChange}
                            onBlur={this.props.onBlurName}
                            disabled={this.props.disabled}>
                        </input>
                        <span className={'errorMsg'}>{this.props.clientMsg}</span>
                        
                        </>
                        
                        }

                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <label for="project">Project Name
                            {this.props.disabled ? "" :
                             <span style={{ color: "red" }}>*</span>}
                             : 
                             {this.props.disabled ? 
                             <b>{this.props.projectName}</b> 
                             : ""}
                        </label>
                            {this.props.disabled ? "" : 
                           <> <input 
                                type="text" 
                                required 
                                placeholder="Project Name" className="form-control" 
                                id="projectName" 
                                value={this.props.projectName}                             
                                onBlur={this.props.onBlurName}
                                onChange={this.props.onProjectandClientChange} disabled={this.props.disabled}>
                            </input>
                            <span className={'errorMsg'}>{this.props.projectMsg}</span>
                        </>
                            }
                    </Grid></Grid>
            </div>
        )
    }
}

export default ClientAndProductInput;
import React from 'react';
//import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import { faPlus, faArrowsAlt, faTimes, faChevronUp, faChevronDown,  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SubTask = ({ addSubTask,sectionData,taskId,subTaskOnchange}) => {
    let sectionId = sectionData.id;
  
    return (
        <tr className="assignment" draggable="true">
            <td className="assignment-head">
                <span className="assignment-name-edit">
                    {/* <select className="assignment-name-select" data-placeholder="Choose a role"  >
                        <option>Choose a role</option>
                        <option data-role-id="92393" data-rate="100">Account Director</option>
                        <option data-role-id="92394" data-rate="75">Project Manager</option>
                        <option data-role-id="92395" data-rate="65">Designer</option>
                        <option data-role-id="92396" data-rate="85">Developer</option>
                        <option>Other</option>
                    </select> */}
                    <input type="text" id={"subTask"+sectionId} placeholder="Sub Task" onChange={(e)=>subTaskOnchange(e,sectionId)}/>
                </span>
                <span className="action-icons">
                    <span className="add-assignment action-icon fa fa-plus" data-toggle="tooltip" data-placement="top" title="" data-original-title="Add Role" onClick={(e)=>addSubTask(e,sectionId,taskId)}><FontAwesomeIcon icon={faPlus} />&nbsp;</span>
                    <span className="move move-assignment action-icon fa fa-arrows" data-toggle="tooltip" data-placement="top" title="" data-original-title="Move Role"><FontAwesomeIcon icon={faArrowsAlt} />&nbsp;</span>
                    <span className="delete action-icon fa fa-times" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete Role"> <FontAwesomeIcon icon={faTimes} />&nbsp;</span>
                </span>
            </td>
            <td className="hours-columns">
                {/* <span className="rate-column">
                    <span className="icon-dollarsperhour" data-toggle="tooltip" data-placement="top" title="" data-original-title="Dollars Per Hour"></span>
                    <input type="text" value="0" className="rate " disabled="disabled" onChange={(e)=>subTaskOnchange(e,sectionId)}/>
                </span>
                <span className="hours-low-column">
                    <span className="icon-hours-low" data-toggle="tooltip" data-placement="top" title="" data-original-title="Hours (Low)"></span>
                    <input type="text" value="0" placeholder="0" className="hours-low" disabled="disabled" />
                </span>
                <span className="hours-likely-column">
                    <span className="icon-hours-likely" data-toggle="tooltip" data-placement="top" title="" data-original-title="Hours (Likely)"></span>
                    <input type="text" value="0" placeholder="0" className="hours-likely " disabled="disabled" />
                </span> */}
                {/* <span className="hours-high-column">
                    <span className="icon-hours-high" data-toggle="tooltip" data-placement="top" title="" data-original-title="Hours (High)"></span>
                    <input type="text" value="0" placeholder="0" className="hours-high " disabled="disabled" id={"hour"+sectionId} placeholder="Sub Task" />
                </span> */}
            </td>
            <td className="subtotal" data-subtotal="0">$  <input type="number" id={"subtotal"+sectionId} placeholder="0" onChange={(e)=>subTaskOnchange(e,sectionId)}/></td>
            <td className="expand-collapse-placeholder"> <span className="collapse" align="right">  <FontAwesomeIcon icon={faChevronUp} /></span>
                <span className="expand" align="right"> <FontAwesomeIcon icon={faChevronDown} /></span></td>
        </tr>
    )
}

export default SubTask;
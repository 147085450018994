import React, { Component } from "react";
import AdLogin from '../../assets/img/ms-symbollockup_signin_light.svg'
import './login.scss';
import microsoftlogo from '../../assets/img/microsoftlogo.png'
export default class LoginWithAD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "Select", 
    };
  }

  handleOptionChange = (event) => {
    const selectedOption = event.target.value;
    this.setState({ selectedOption });
  };

  handleSignInWithMicrosoft = () => {
    const { selectedOption } = this.state;
    if (selectedOption === "Cullinary") {
      window.open(
        "https://sso-service.fulcrumone.net/createaccesstoken/cd/estimator",
        "_self"
      );
    } else if (selectedOption === "FulcrumOne") {
      window.open(
        "https://sso-service.fulcrumone.net/createaccesstoken/fd/estimator",
        "_self"
      );
    }
  };

  render() {
    return (
      <>
        <div className="textOnSelect">
        <label>Select Organization</label>
        <select
          className="form-control"
          value={this.state.selectedOption}
          onChange={this.handleOptionChange}
          displayEmpty
          inputProps={{
            name: "apiOption",
            id: "select-api-option",
          }}
        >
          <option value="Select">Select</option>
          <option value="Cullinary">Culinary Digital</option>
          <option value="FulcrumOne">Fulcrum Digital</option>
        </select>
        </div>
        <button className="passbtn" id="btnpwd" onClick={this.handleSignInWithMicrosoft}>
          <img className="micrsoftimg" src={microsoftlogo} alt="logo" />
          Sign in with Microsoft
        </button>
      </>
    );
  }
}
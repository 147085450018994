import React from "react";
 import './login.scss';
import '../../App.css';
import DynamicForm from 'f1-dynamic-form-react';
import Header from '../header/loginHeader';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
     
      current: { id: 1},

      formData: [
        { "key": "email", "type": "email", "disabled": false, "id": "email", "icon": "user", "label": "User Name", "validators": [{ "name": "required", "value": true }] },
         ]

    };
  }
  componentDidMount(){
    let colors = ['#87d5d473', '#ffd0976e', '#72c6fa6e', '#8000807d', "#28a74559","#dc35456e"];
    let selectedColor = colors[Math.floor(Math.random() * colors.length)]
    document.getElementsByTagName("body")[0].style.backgroundColor=selectedColor;
  }

  onChangeHandler = (event, key) => {
    //console.log("On change triggered!!!!!!!", event.target.value, key);
    //this.setState({key:event.target.value})
    //console.log("state values",this.state)
  }

  submitForm = (event, value) => {
    //console.log(event);
   

  };
  
  render() {
    return (
      <div className="main-container forgotPage">
        <Header history={this.props.history}/>
        <Grid container justify="center"
  alignItems="left" >
          <Grid item sm={6} md={4} direction="column">
          <Paper className={""} style={{textAlign:"left"}}>
              <DynamicForm
          key={this.state.current.id}
          className="form horizontal"
          title="Forgot Password?"
          buttonSubmit="Submit"
          // defaultValues={this.state.current}
          model={this.state.formData}
          onSubmit={this.submitForm}
          disableSubmitOnInValid={false}
          customValidators={this.customValidators}
          displayInformation={true}
          displaydetails=""
          design="bootstrap" // material/bootrstap/advanceMaterial/advanceBootstrap
          materialOutline={true}
          extErrors={this.state.extErrors}
          onChange={this.onChangeHandler}
        />
            </Paper>  
          </Grid>
        </Grid>
      
      </div>
    );
  }
}

export default ForgotPassword;

import React from 'react';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import { faPlus, faArrowsAlt, faTimes, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SubTask from '../SubTask/subTask'

import TextareaAutosize from 'react-textarea-autosize'
class TableTask extends React.Component {
    constructor() {
        super()
        this.state = {

        }
    }

    onCollapse = (e, id) => {
        this.setState({ [e.target.id]: !this.state[e.target.id] })
        //console.log(e.target.value,id,this.state[e.target.id])
    }
    taskChange = (e, id, taskId, name, check) => {
        this.props.taskOnchange(e, id, taskId, name, check);

    }
    taskCheck = (e, id, taskId, name) => {
        this.props.taskChangeCheck(e, id, taskId, name);
    }

     textAreaAdjust=(element) =>{
        element.style.height = "1px";
        element.style.height = (25+element.scrollHeight)+"px";
        console.log(element.style.height,element.scrollHeight,"textarea")
      }
// componentDidMount(){
    
//     document.getElementById("task" + this.props.data.taskId).height= document.getElementById("task" + this.props.data.taskId).scrollHeight();
// }
componentDidMount() {
  //  const height = this.divElement.clientHeight;
  //let el=document.getElementById('task'+this.props.data.taskId).value;

  
  console.log(document.getElementById('task'+this.props.data.taskId),document.getElementById('task'+this.props.data.taskId).scrollHeight+'px')
  }
    render() {
        const { addTask, sectionData, addSubTask, data, taskOnchange, subTaskOnchange, onDeleteTask } = this.props;
        let id = sectionData.sectionId;
        let taskId = data.taskId
        let sectionTask = data.taskTotal;
        let sectionTaskF1 = data.fOneTaskTotal;
       
        // el={width:"100%",overflow:"hidden",height:document.getElementById('task'+this.props.data.taskId).scrollHeight+'px'};
        return (
            <Table className="table task ui-sortable" draggable="true">
                <tbody>
                    <tr>
                        <th className="task-head">
                            <span className="task-name">
                                <TextareaAutosize  style={{width:"100%",overflow:"hidden",height:this.scrollHeight+'px'}} type="textarea" name="taskName" id={"task" + taskId} title={this.state["task" + taskId] || data.taskName} value={this.state["task" + taskId] || data.taskName} placeholder="User story" onChange={(e) => this.taskChange(e, id, taskId, "name")}  onBlur={this.props.autoSaveOnBlur}/>
                            </span>


                        </th>
                        <th style={{textAlign:'center'}}>    <span className="usingf1Div"><span>
                            <input type="checkbox" id={"usingFOne" + taskId} checked={this.state["usingFOne" + taskId] || data.usingFOne} onChange={(e) => this.taskChange(e, id, taskId, "usingFOne")} onBlur={this.props.autoSaveOnBlur}/>
                        </span>

                        </span></th>

                        <th className="task-total" data-total-mean="0">
                            <input type="number" min="0" name="taskTotal" align="right" value={this.state["taskTotal" + taskId] || data.taskTotal} id={"taskTotal" + taskId} placeholder="0" onChange={(e) => this.taskChange(e, id, taskId, "total", data.usingFOne)} onBlur={this.props.autoSaveOnBlur} />
                        </th>


                        <th className="task-space">
                            {/* {data.usingFOne?(this.state["fOneTaskTotal" + taskId] || data.fOneTaskTotal):(this.state["taskTotal"+taskId] || data.taskTotal)} */}
                            
                            <input type="number" min={"0"} max={this.state["taskTotal" + taskId] || data.taskTotal} id={"fOneTaskTotal" + taskId}
                                value={data.usingFOne ? (this.state["fOneTaskTotal" + taskId] || data.fOneTaskTotal) : (this.state["taskTotal" + taskId] || data.taskTotal)}
                                placeholder="0"
                                onChange={(e) => {
                                    if (e.target.value <= data.taskTotal) {
                                        this.taskChange(e, id, taskId, "fOneTaskTotal")
                                    }

                                }}

                                title="F1 efforts should be less than BUILD efforts"

                                className="usingf1"

                                disabled={!data.usingFOne} /></th>
                        <th>
                            <span className="action-icons" style={{paddingLeft:'9px'}}>
                                <span className="add-task action-icon" style={{color:'green'}} title="" onClick={(e) => addTask(e, id)}>  <FontAwesomeIcon icon={faPlus} />&nbsp;</span>
                                <span className="move move-task action-icon drag-handle-task" title="" > <FontAwesomeIcon icon={faArrowsAlt} />&nbsp;</span>
                                <span className="delete action-icon" style={{color:'#d40b0b'}} title="" onClick={(e) => onDeleteTask(e, id, taskId, sectionTask, sectionTaskF1)}> <FontAwesomeIcon icon={faTimes} />&nbsp;</span>
                            </span>
                        </th>
                        <th className="task-expand-collapse" ID={"taskCollapse" + id} onClick={(e) => this.onCollapse(e, id)}>
                            {/* <span className="collapse" align="right">  <FontAwesomeIcon icon={faChevronUp} /></span>
                        <span className="expand" align="right"> <FontAwesomeIcon icon={faChevronDown} /></span> */}
                            <FontAwesomeIcon icon={this.state["taskCollapse" + id] ? faChevronUp : faChevronDown} />

                        </th>

                        {/* <th className="section-expand-collapse" id={"sectionCollapse"+id} onClick={(e)=>this.onCollapse(e,id)} >
                            <FontAwesomeIcon icon={this.state["sectionCollapse"+id]?faChevronUp:faChevronDown } />
                           
                        </th> */}
                    </tr>

                    {!this.state["taskCollapse" + id] ?
                        data.subTask && data.subTask.map(subItem =>
                            <SubTask addSubTask={addSubTask} taskId={data.taskId} sectionData={sectionData} subTaskOnchange={subTaskOnchange} />)
                        : ""}
                </tbody>
            </Table>
        )
    }
}

export default TableTask;

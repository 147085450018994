import React from 'react';
import ClientAndProductInput from '../clientAndProductInput/clientAndProductInput';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import { get, post } from '../../api/api';
import { GET_COLLABORATOR } from '../../api/baseURL';
class Collaborator extends React.Component {
    constructor() {
        super();
        this.state = {
            value:[],
            collaborator:[]
        }
    }
componentDidMount(){
    post(GET_COLLABORATOR+"Common").then(res => {
        this.setState({collaborator:res.data})
    })
}
onSave = (e) => {
    e.preventDefault();
  
        this.props.onSubmit()

}

onBack = (e) => {
    e.preventDefault();
    this.props.history.push("/home")
}
    render() {
       
        return (

            <form id="summary">
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={12}>
                        <div style={{ textAlign: "left" }} className="sdlc-table">

                            <div style={{ marginBottom: "5px" }}>
                                <Snackbar open={this.state.showMsg} autoHideDuration={6000} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })}><Alert severity="error" >Client Name, Project Name,Estimation Metadata, Questionnaire and Sizing are required fields!</Alert></Snackbar>

                                <ClientAndProductInput
                                    onProjectandClientChange={this.props.onProjectandClientChange}
                                    projectName={this.props.projectName}
                                    clientName={this.props.clientName}
                                    disabled={true}
                                    />

                                    <Autocomplete
                                            multiple
                                            limitTags={10}
                                            id="multiple-limit-tags"
                                            options={this.state.collaborator}
                                            getOptionLabel={(option) => option.email}
                                            value={this.props.collaboratorValue}
                                            defaultValue={this.props.collaboratorValue}
                                            onChange={(event, newValue) => {
                                                this.props.onChangeCollaborator(event, newValue)
                                              this.setState({value:newValue});
                                              //console.log(newValue, this.state.value)
                                            }}
                                            
                                            renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Collaborators" placeholder="Collaborators" />
                                            )}
                                        />
                            </div>  
                            <div style={{ float: "right" }}>
                            <Button variant="contained" color="primary" onClick={this.onBack}>
                               Back to Dashboard
                            </Button>&nbsp;
                    <Button type="submit" variant="contained" color="primary" onClick={this.onSave}>
                                Save
                            </Button> &nbsp;

                            </div>
                        </div>
                    </Grid>
                </Grid>
            </form>
        )
    }
}

export default Collaborator;
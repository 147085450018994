import React from 'react'
import './estimateViewer.scss'

class EstimateViewer extends React.Component{
    constructor(){
        super()
        this.state={}
    }
    render(){
        return(
            <div className="total-area affix-top">
                  <div className="average-total total-block">
        <h5 style={{fontSize: "18px",color:"#fff"}}>
            {this.props.label?this.props.label:"EFFORT HOURS"} 
        </h5>
        {this.props.renderView=="points" ?
        <h3 className="total-mean">
           
                <span id="total-mean">{this.props.allSectionTotal}</span> 
           
        </h3>:
        
        <h3 className="total-mean">
            <span>
                <span id="total-mean">{this.props.allStoryPoint}</span> Hrs.
            </span> 
           
        </h3>
    //      <h3 className="total-mean">
             
    //      <span>
    //          <span id="total-mean">{this.props.allSectionTotal}</span> Hrs.
    //      </span> 
        
    //  </h3>
    }
        {this.props.renderView=="points" ?"": 
        
        <h5 style={{color:"#fff"}}><span > ({Math.round( this.props.allSectionTotal)}</span> days effort )</h5>}
                </div>
               
            </div>
            )
    }
}

export default EstimateViewer;
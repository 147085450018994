import React from 'react';
import './App.css';
import { Route, Switch, Redirect,BrowserRouter as Router } from "react-router-dom";
import Estimate from './container/estimate/estimate'
import ViewEstimate from './container/estimate/viewEstimate'
import Login from './components/login/login'
import Home from './components/Home/home'
import {Landing} from './components/landing/landing';
import Register from './components/register/register';
import ForgotPassword from './components/login/forgotPassword';
import Logout from './components/login/logout';
import Contacts from './components/contacts/contacts'
import Assumptions from './components/assumptions/assumptions';
import ChangePassword from './components/changePassword/changePassword';
import ReactGA from 'react-ga';
// //import ReactGA from 'react-ga';
// ReactGA.initialize('G-SVM1GMWGM6'); 
// ReactGA.pageview(window.location.pathname + window.location.search);
export const PrivateRoute = ({component: Component, ...rest}) => {
  return (

      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /signin page.!
      <Route {...rest} render={props => (
        localStorage.getItem('token') ?
              <Component {...props} />
          : <Redirect to="/" />
      )} />
  );
};

export const PublicRoute = ({component: Component, restricted, ...rest}) => {
return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route {...rest} render={props => (
      localStorage.getItem('token') && restricted ?
            <Redirect to="/home" />
        : <Component {...props} />
    )} />
);
};

class App extends React.Component{
  constructor(){
super()

  }


  componentDidMount(){
    ReactGA.initialize('G-TD0RX9W035');
    ReactGA.pageview(window.location.pathname + window.location.search)
    let token;
    let accessToken = undefined;
    let isAuthenticated = localStorage.getItem('token')?true:false
    const landing = window.location.href;
    if (landing.split("#")[1] !== undefined) {
      accessToken = landing.split("#")[1].split("=")[1];
       token = accessToken?.split("&")[0]
    }
    if (accessToken !== undefined) {
      localStorage.setItem("token",token);
      window.location.href = `#/home`;
    }
  }
  render(){
  return (
    <div className="App">       
          <Switch>
            
          <PublicRoute restricted={false} path="/" component={Login} exact />
          <PublicRoute restricted={false} path="/logout" component={Logout} exact />
            <PublicRoute restricted={false} path="/forgotPassword" component={ForgotPassword} exact/> 
            {/* <PublicRoute restricted={false} path="/register" component={Register} exact/>  */}
            <PublicRoute restricted={false} path="/contact" component={Contacts} exact/>
          
            <PrivateRoute path="/home" component={Home} exact/>
            <PrivateRoute path="/estimate" component={Estimate} exact/>
            <PublicRoute restricted={false} path="/viewEstimate/:projectId" component={ViewEstimate} exact/>
            <PrivateRoute path="/landing" component={Landing} exact/>
            <PrivateRoute path="/assumptions" component={Assumptions} exact/>
            <PrivateRoute path="/changePassword" component={ChangePassword} exact/>
          </Switch>
       
    </div>
  );
  }
}

export default App;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./TaskViewer.scss";
import taskViewer from "./newJSON.json"
import TableSection from '../tableSection/tableSection'
import TableTask from '../TableTask/tableTask'
import { Container, Draggable } from 'react-smooth-dnd';
import EstimateViewer from '../estimateViewer/estimateViewer';
import ChartViewer from '../charts/pieChart';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import ClientAndProductInput from '../clientAndProductInput/clientAndProductInput'



class TaskViewer extends React.Component {
    constructor() {
        super();
        this.state = {

            show: false,
            alertText: "",
            rightOpen: true,
            projectName: "",
            clientName: "",
            showMsg: false
        }
    }
    onSave = (e) => {
        e.preventDefault();
        if (this.props.clientName && this.props.projectName) {
            this.props.onSubmit()
            this.setState({ showMsg: false })
        } else {
            this.setState({ showMsg: true })
        }
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ showMsg: false })
    };

    onBack = (e) => {

        e.preventDefault();
        this.props.history.push("/home")
      }

    toggleSidebar = (event) => {
        let key = `${event.currentTarget.parentNode.id}Open`;
        this.setState({ [key]: !this.state[key] });
    }

    render() {
        localStorage.setItem("updatedTotal", this.state.allSectionTotal)
        localStorage.setItem("f1Efforts", this.state.allSectionTotalF1)

        let section = JSON.stringify(this.state.data)
        localStorage.setItem("sectionModel", section)

        let taskViewer = JSON.stringify(this.state.displaySdlc)
        let rightOpen = this.state.rightOpen ? 'open' : 'closed';

        return (
            <form id="taskViewer">

<div id='layout'>
          <div className='content'>



                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <div style={{ textAlign: "left" }} className="estimate-table">
                            {/* <TosterNotification
                                variant={'success'}
                                //['primary','secondary','success','danger','warning','info','light','dark']     
                                show={this.state.show}
                                dismissible={true}
                                onclose={this.onclose}
                                alertText={"This is a warning alert with dismissible check it out!!"}
                                autoClose={true}
                                delay={3000}
                            /> */}

                            <Snackbar open={this.state.showMsg} autoHideDuration={6000} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })}><Alert severity="error" >Client and product name are required fields!</Alert></Snackbar>


                            <div style={{ marginBottom: "5px" }}>

                                <ClientAndProductInput
                                    onProjectandClientChange={this.props.onProjectandClientChange} 
                                    autoSaveOnBlur={this.props.autoSaveOnBlur}
                                    projectName={this.props.projectName} 
                                    clientName={this.props.clientName}
                                    disabled={true}
                                />
                                 <div style={{textAlign:"center"}}>1 storypoint = <input type="number" disabled={true} min={1} onChange={this.props.storyPointChange} value={this.props.allSectionStoryPoint} style={{width:"45px"}}></input> Hours</div>
                        
                            </div>

                            <div className="tabBox">
                           <Container
                             onDrop={dropResult => this.props.onDrop(dropResult)} 
                             onDragEnter={()=>{}}
                             onDragLeave={()=>{}}
                             lockAxis={'y'}
                             dragHandleSelector=".drag-handle"
                             >
                                {this.props.data && this.props.data.map(el => {

                                    return <TableSection
                                        addSection={this.props.addSection}
                                        color={el.sectionColor}
                                        sectionData={el}
                                        changeColor={this.props.changeColor}
                                        hideShowPallete={this.props.hideShowPallete}
                                        sectionOnchange={this.props.sectionOnchange}
                                        autoSaveOnBlur={this.props.autoSaveOnBlur}
                                        onDeleteSection={this.props.onDeleteSection}
                                        onDropTask={this.props.onDropTask}

                                        taskData={
                                        <Container 
                                        lockAxis={'y'}
                                        dragHandleSelector=".drag-handle-task"
                                        onDrop={(dropResult) => this.props.onDropTask(dropResult, el.sectionId)} >    
                                        {el.taskModel && el.taskModel.map(item =>
                                            <Draggable key={"id"}>
                                                <TableTask
                                                    addTask={this.props.addTask}
                                                    color={"turquoise"}
                                                    data={item}
                                                    sectionData={el}
                                                    taskChangeCheck={this.props.taskChangeCheck}
                                                    // addSubTask={this.addSubTask}
                                                    //  subTaskOnchange={this.subTaskOnchange}
                                                    taskOnchange={this.props.taskOnchange}
                                                    autoSaveOnBlur={this.props.autoSaveOnBlur}
                                                    onDeleteTask={this.props.onDeleteTask}
                                                />
                                            </Draggable>
                                        )}
                                        </Container>
                                        }
                                    />
                                }
                                )}

                            </Container>
                            
                        </div>
                        </div>
                        <div style={{ float: "right" }}>
                            <Button variant="contained" color="primary" onClick={this.onBack}>
                               Back to DASHBOARD
                            </Button>&nbsp;
                    <Button type="submit" variant="contained" color="primary" onClick={this.onSave}>
                                Save
                            </Button> &nbsp;

                            </div>
                    </Grid>

                </Grid>
                </div>

                <div id='right' >
            <div className='icon'
              onClick={this.toggleSidebar} >
              &equiv;
            </div>
            <div className={`sidebar ${rightOpen}`} >

            <EstimateViewer 
            allSectionTotal={this.props.allSectionTotal} 
            allStoryPoint={this.props.allSectionTotal*this.props.allSectionStoryPoint} 
            label="BUILD STORYPOINT W/O F1" renderView={"points"}/>

             <EstimateViewer allSectionTotal={this.props.allSectionTotalF1} allStoryPoint={this.props.allSectionTotalF1*this.props.allSectionStoryPoint}  label="BUILD STORYPOINT WITH F1" renderView={"points"}/>
            {/* <ChartViewer data={this.props.chartData} /> */}


            <EstimateViewer 
            allSectionTotal={this.props.allSectionTotal} 
            allStoryPoint={this.props.allSectionTotal*this.props.allSectionStoryPoint} 
            label="BUILD EFFORT W/O F1" renderView={"points&hours"}/>
            <h5 className="average-total total-block" style={{marginBottom:0,color:'#fff'}}><b>Using F1 Saves <span className="percentH">{this.props.f1TotalPercentReduce ?Math.round(this.props.f1TotalPercentReduce): 0} %</span></b></h5>
            <EstimateViewer allSectionTotal={this.props.allSectionTotalF1} allStoryPoint={this.props.allSectionTotalF1*this.props.allSectionStoryPoint}  label="BUILD EFFORT WITH F1" renderView={"points&hours"}/>

                        {/* <ChartViewer data={this.props.chartDataF1} /> */}
              


            </div>
          </div>

</div>
            </form>
        )
    }
}

export default TaskViewer;
import React from 'react';
import Grid from '@material-ui/core/Grid';
import './estimate.scss';
import '../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import TaskViewer from '../../components/TaskViewer/TaskViewer'
import Header from '../../components/header/header'
import SDLCViewer from '../../components/sdlc/sdlcViwer'
import TabPanel from 'f1-tab-panel-react'
import Assumptions from '../../components/assumptions/assumptions';
import Summary from '../../components/devlopmentEstimate/summary';
import Collaborator from '../../components/collaborators/collaborator';
import SprintMatrix from '../../components/sprintMatrix/sprintMatrix'
import { get, post } from '../../api/api';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { SHARE_ESTIMATE_BY_PROJECTID } from '../../api/baseURL'
import ModalPopup from 'f1-modal-react';
import { Button } from "@material-ui/core";
import F1HeaderNav from 'f1-header-navigation-react'
import logo from '../../assets/img/logoSqr.jpg'

// import GetAppIcon from '@material-ui/icons/GetApp' ;

import taskViewer from "../../components/TaskViewer/newJSON.json"

class ViewEstimate extends React.Component {
  constructor() {
    super();
    this.state = {
      taskMax: 0,
      showCapacityAlert:false,
      storyPoint: 8,
      sprintMatrixState: 0,
      showModal: false,
      dataForSprintMatrixCalculation: [],
      sprintColumns: [{
        field: "taskName",
        prop: "taskName",
        title: "User Story",

      },
      {
        field: "sprintMatrix",
        prop: "sprintMatrix",
        title: "Efforts in hours",
        cell: (props) => {
          return Math.round(props.sprintMatrix)
        }
      }],
      sprintColumnsF1: [{
        field: "taskName",
        prop: "taskName",
        title: "User Story",
        width: '700px',

      },
      {
        field: "sprintMatrixF1",
        prop: "sprintMatrixF1",
        title: "Efforts in hours",
        cell: (props) => {
          return Math.round(props.sprintMatrixF1)
        }
      }],
      sprintMatrixData: [],
      sprintMatrixDataF1: [],
      showSprintMatrixAlert: false,
      sprintValue: 0,
      modalShow: false,
      showSaveMsg: false,

      clientMsg: "",
      projectMsg: "",
      finalTotal: 0,
      key: "summarytab",
      projectName: "",
      clientName: "",
      data: [],
      allSectionTotal: 0,
      allSectionTotalF1: 0,
      f1TotalPercentReduce: 0,
      collaboratorValue: [],
      chartData: {
        labels: [
          'Section1',
          'Section2',

        ],
        datasets: [{
          data: [0, 0],
          backgroundColor: [
            'turquoise',
            'yellow',

          ]
        }]
      },
      chartDataF1: {
        labels: [
          'Section1',
          'Section2',

        ],
        datasets: [{
          data: [0, 0],
          backgroundColor: [
            'turquoise',
            'yellow',
          ]
        }]
      },
      showMsg: false,
      displaySdlcData: [],
      displaySdlc: [
        {
          id: "1",
          activity: 'Project Management',
          reference: '5',
          // allocated: '5',
          budgeted: '0',
          // f1Allocated:'5',
          fOneRefrence: '5',
          fOneBudgeted: '0',
          justification: ""
        },
        {
          id: "2",
          activity: 'Requirement Gathering',
          reference: '12',
          //allocated: '12',
          budgeted: '0',
          //sf1Allocated:'12',
          fOneRefrence: '12',
          fOneBudgeted: '0'

        },
        {
          id: "3",
          activity: 'Technical Design / Architecture',
          reference: '15',
          //allocated: '15',
          budgeted: '0',
          //f1Allocated:'15',
          fOneRefrence: '15',
          fOneBudgeted: '0',
          justification: ""
        },
        {
          id: "4",
          activity: 'UX Design (Hrs.)',
          reference: '0',
          budgeted: '0',
          //f1Allocated:'5',
          fOneRefrence: '0',
          fOneBudgeted: '0',
          justification: ""
        },
        {
          id: "5",
          activity: 'Code Construction',
          reference: '50',
          //allocated: '50',
          //f1Allocated:'50',
          fOneRefrence: '50',
          fOneBudgeted: '0',
          justification: ""
        },
        {
          id: "6",
          activity: 'QA Testing & Support',
          reference: '11',
          //allocated: '11',
          budgeted: '0',
          //f1Allocated:'11',
          fOneRefrence: '11',
          fOneBudgeted: '0',
          justification: ""
        },
        {
          id: "7",
          activity: 'Performance Testing / VAPT',
          reference: '1',
          //allocated: '1',
          budgeted: '0',
          //f1Allocated:'1',
          fOneRefrence: '1',
          fOneBudgeted: '0',
          justification: ""
        }, {
          id: "8",
          activity: 'Deployment, Release and UAT Support',
          reference: '6',
          //allocated: '6',
          budgeted: '0',
          //f1Allocated:'6',
          fOneRefrence: '6',
          fOneBudgeted: '0',
          justification: ""
        },
        {
          id: "12",
          activity: 'TOTAL (A)',
          reference: '0',
          budgeted: '0',
          //f1Allocated:'',
          fOneRefrence: '0',
          fOneBudgeted: '0',
          justification: ""
        }, {
          id: "13",
          activity: 'CONTINGENCY (B)',
          reference: '0',
          budgeted: '0',
          //f1Allocated:'',
          fOneRefrence: '0',
          fOneBudgeted: '0',
          justification: ""
        }, {
          id: '14',
          activity: 'BUDGETED TOTAL COST (A + B)',
          reference: '0',
          budgeted: '0',
          //f1Allocated:'',
          fOneRefrence: '0',
          fOneBudgeted: '0',
          justification: ""
        }, {
          id: "15",
          activity: 'ONE-TIME DISCOUNT (C)',
          reference: '0',
          budgeted: '0',
          //f1Allocated:'',
          fOneRefrence: '0',
          fOneBudgeted: '0',
          justification: ""

        }, {
          id: "16",
          activity: 'BUDGETED TOTAL COST AFTER DISCOUNT (A + B - C)',
          reference: '0',
          budgeted: '0',
          //f1Allocated:'',
          fOneRefrence: '0',
          fOneBudgeted: '0',
          justification: ""
        }
      ],
      estimationMetadata: [
        {
          id: "1",
          key: "Opportunity Name",
          value: ""
        },
        {
          id: "2",
          key: "Project Name",
          value: ""
        },
        {
          id: "3",
          key: "Estimated By",
          value: localStorage.getItem("email")
        },
        {
          id: "4",
          key: "Version",
          value: "1"
        },
        {
          id: "5",
          key: "Technology Platform",
          value: ""
        }
      ],
      questionnaire: [
        {
          id: "1",
          key: "Is this a web application?",
          value: "no"
        },
        {
          id: "2",
          key: "Is this a mobile application?",
          value: "no"
        },
        {
          id: "3",
          key: "Is data migration involved?",
          value: "no"
        },
        {
          id: "4",
          key: "Does this project involve BI?",
          value: "no"
        },
        {
          id: "5",
          key: "Does this project integrate to external systems?",
          value: "no"
        },
        {
          id: "6",
          key: "Is this a microservice application?",
          value: "no"
        },
        {
          id: "7",
          key: "Is this a PWA compatable application?",
          value: "no"
        },
        {
          id: "8",
          key: "Does mobile/web support offline capabilities?",
          value: "no"
        },
        {
          id: "9",
          key: "Is hosting of the solution expected?",
          value: "no"
        },
        {
          id: "10",
          key: "Is LDAP or AD involved for authentication?",
          value: "no"
        },
        {
          id: "11",
          key: "Is the authentication local?",
          value: "no"
        }
      ],
      sizing: [
        {
          id: "1",
          key: "Number of users using the system",
          value: ""
        },
        {
          id: "2",
          key: "Number of concurrent users (%)",
          value: ""
        },
        {
          id: "3",
          key: "Volume of users added YOY (%)",
          value: "20"
        }, {
          id: "4",
          key: "Current data volume (GB)",
          value: "0"
        }, {
          id: "5",
          key: "Page response time (ms)",
          value: "500"
        },

      ],
      message: "",
      msgType: "",
      createdByEmail: "",
      autoSave:false
    }
  }

  onRedirect=()=>{
    this.props.history.push("/home")
    console.log("history",this.props.history.location.pathname)
  }

  headerConfig = {
    navBarStyle : this.navBarStyle,
    navBarClassName : 'my-custom-nav-bar',
    brandType : 'text', // text/image/component, required
    brandText: <span className="branding"  onClick={this.onRedirect}><img src={logo} style={{width:"35px", height:"35px"}}/><b>FULCRUM ONE ESTIMATOR</b></span>,
    brandLink: '',
    brandImgUrl: "", // in case of type === image
    brandClass: 'my-brand', // custom class for brand styling
    disableHambergerIcon: true, // default true
    menus:{
        
      }
  }

  componentWillMount() {

    this.setState({
      data: [
        {
          "sectionId": "1",
          "sectionName": "",
          "sectionDescription": "string",
          "sectionColor": "purple",
          "sectionTotal": 0,
          "f1Efforts": 0,
          "taskModel": [
            {
              "taskId": 1,
              "taskName": "",
              "taskTotal": 0,
              "usingFOne": false,
              "fOneTaskTotal": 0,
              "auditModel": {
                "createdBy": "string",
                "updatedBy": "string",
                "createdDateTime": "2020-10-29T02:05:19.329Z",
                "updatedDateTime": "2020-10-29T02:05:19.329Z"
              }
            },
            {
              "taskId": 2,
              "taskName": "",
              "taskTotal": 0,
              "usingFOne": true,
              "fOneTaskTotal": 0,
              "auditModel": {
                "createdBy": "string",
                "updatedBy": "string",
                "createdDateTime": "2020-10-29T02:05:19.329Z",
                "updatedDateTime": "2020-10-29T02:05:19.329Z"
              }
            }
          ],
          "auditModel": {
            "createdBy": "string",
            "updatedBy": "string",
            "createdDateTime": "2020-10-29T02:05:19.329Z",
            "updatedDateTime": "2020-10-29T02:05:19.329Z"
          }
        }
      ]
    })
  }
  componentDidMount() {
    //console.log("submifirstDidtfinal")
    console.log("This is data", btoa(unescape(encodeURIComponent(2299))), this.props.match.params.projectId, decodeURIComponent(escape(window.atob(this.props.match.params.projectId))))
      let rowData = localStorage.getItem("rowData");
      let projectId = decodeURIComponent(escape(window.atob(this.props.match.params.projectId)))
  
      get(SHARE_ESTIMATE_BY_PROJECTID + projectId).then(res => {

        let rowDetails = res.data;
        //console.log('rowDetails',rowDetails)

        let autoGenerateCol = [];
        rowDetails.sprintMatrixes && rowDetails.sprintMatrixes[0].sprints.map((c, i) => {
          autoGenerateCol.push({
            field: c.id,
            prop: c.id,
            title: (c.id).toUpperCase(),
            align: "right",
            cell: (props) => {
              return <input type="checkbox" className="form-control checkbox" onChange={(e) => this.onChangeSelectedSprint(e, props, true)} checked={props.sprints[i].value} id={props.sprints[i].id} />
            }
          })
        })
        let autoGenerateColF1 = [];
        rowDetails.sprintMatrixesF1 && rowDetails.sprintMatrixesF1[0].sprints.map((c, i) => {
          autoGenerateColF1.push({
            field: c.id,
            prop: c.id,
            title: (c.id).toUpperCase(),
            align: "right",
            cell: (props) => {
              return <input type="checkbox" className="form-control checkbox" onChange={(e) => this.onChangeSelectedSprintF1(e, props, true)} checked={props.sprints[i].value} id={props.sprints[i].id} />
            }
          })
        })
        this.setState({
          //  dataForSprintMatrixCalculation: res.data,
          sprintMatrixState: rowDetails.sprintMartrixCapacity,
          sprintValue: rowDetails.numberOfSprints,
          sprintColumns: [...this.state.sprintColumns, ...autoGenerateCol],
          sprintColumnsF1: [...this.state.sprintColumnsF1, ...autoGenerateColF1],
          sprintMatrixData: rowDetails.sprintMatrixes ? rowDetails.sprintMatrixes : [],
          sprintMatrixDataF1: rowDetails.sprintMatrixesF1 ? rowDetails.sprintMatrixesF1 : [],
          clientName: rowDetails.clientName,
          projectName: rowDetails.projectName,
          data: rowDetails.sectionModel,
          allSectionTotal: rowDetails.projectTotal,
          allSectionTotalF1: rowDetails.f1Efforts,
          f1TotalPercentReduce: ((parseInt(rowDetails.projectTotal) - parseInt(rowDetails.f1Efforts)) / parseInt(rowDetails.projectTotal)) * 100,
          displaySdlc: rowDetails.effortsSdlc,
        })
      })

  }

  render() {

    return (
      
      <div className="main-container" id="estimate">
        
        <form>
          <F1HeaderNav
            config={this.headerConfig}
            brandComponent={<this.CustomBrand />} // in case of type === component
          />
          <Grid container >
            <Grid item md={12} spacing={2}>
                <SDLCViewer
                    history={this.props.history}
                    projectName={this.state.projectName}
                    clientName={this.state.clientName}
                    displaySdlc={this.state.displaySdlc}
                    allSectionTotal={this.state.allSectionTotal}
                    allSectionTotalF1={this.state.allSectionTotalF1}
                    allSectionStoryPoint={this.state.storyPoint}
                    f1TotalPercentReduce={this.state.f1TotalPercentReduce}
                />
            </Grid>

          </Grid>

        </form>
      </div>
    )
  }
}

export default ViewEstimate;
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import "./sdlc.scss";
//import taskViewer from "./newJSON.json"
import TableSection from '../tableSection/tableSection'
import TableTask from '../TableTask/tableTask'
import { Container, Draggable } from 'react-smooth-dnd';
import EstimateViewer from '../estimateViewer/estimateViewer';
import ChartViewer from '../charts/pieChart';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { get, post } from '../../api/api';
import { EDIT_ESTIMATE_BY_PROJECTID } from '../../api/baseURL';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import MatDatagrid from "f1-mat-data-grid-react";
import TextField from '@material-ui/core/TextField';
import ClientAndProductInput from '../clientAndProductInput/clientAndProductInput'

import TextareaAutosize from 'react-textarea-autosize'

class Assumptions extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      alertText: "",
      data: [],
      allSectionTotal: 0,
      rightOpen: true,
      chartData: {
        labels: [
          'Section1',
          'Section2',

        ],
        datasets: [{
          data: [0, 0],
          backgroundColor: [
            'turquoise',
            'yellow',

          ]
        }]
      },
      projectName: "",
      clientName: "",
      showMsg: false,
      displaytechAssumption: [],
      displayGenAssumption: [],
      displayOutOfScope: [],
      columnsTechAssumption: [
        { title: 'Id', field: 'id', editable: 'never', sorting: true, defaultSort: 'asc' },
        {
          title: 'ASSUMPTIONS', field: 'description', sorting: false, render: (props) => {
            //console.log(props)
            return (<div>
              <TextareaAutosize min={1} id={"description" + props.id} className={"form-control"} value={this.state["description" + props.id] || props.description} defaultValue={props.description} onChange={(e) => this.assumptionFieldChange(e, props.id, "techDescription")} onBlur={this.props.autoSaveOnBlur} />

            </div>)
          }
        }
      ],
      columnsGenAssumption: [
        { title: 'Id', field: 'id', editable: 'never', sorting: true, defaultSort: 'asc' },
        {
          title: 'ASSUMPTIONS', field: 'description', sorting: false, render: (props) => {
            //console.log(props)
            return (<div>
              <TextareaAutosize min={1} id={"description" + props.id} className={"form-control"} value={this.state["description" + props.id] || props.description} defaultValue={props.description} onChange={(e) => this.assumptionFieldChange(e, props.id, "genDescription")} onBlur={this.props.autoSaveOnBlur} />

            </div>)
          }
        }
      ],
      columnsOutOfScope: [
        { title: 'Id', field: 'id', editable: 'never', sorting: true, defaultSort: 'asc' },
        {
          title: 'Assumptions', field: 'description', sorting: false, render: (props) => {
            //console.log(props)
            return (<div>
              <TextareaAutosize min={1} id={"description" + props.id} className={"form-control"} value={this.state["description" + props.id] || props.description} defaultValue={props.description} onChange={(e) => this.assumptionFieldChange(e, props.id, "outOfScopeDescription")} onBlur={this.props.autoSaveOnBlur} />

            </div>)
          }
        }
      ],
    }
  }

  addRow = () => {
    let newData = { description: "" }
let getSortNumberArray=this.state.displaytechAssumption.length>0 && this.state.displaytechAssumption.map(r=>{
  r.id=parseInt(r.id)
  return r
})
    let idArray =getSortNumberArray.length > 0 ?
    getSortNumberArray && getSortNumberArray.sort(
        function (a, b) {
          console.log( parseFloat(a['id']), parseFloat(b['id']))
          return parseInt(b['id']) - parseInt(a['id']);
        }
      )[0]['id']
      : 0;
    //console.log("addRow99", newData,idArray,this.state.displaytechAssumption)
    //console.log("Maxxx",idArray,newData)  
    idArray=parseInt(idArray)
    let newRowData = Object.assign({ id: (idArray + 1).toString() }, newData)
    //console.log(newRowData,"idArray")

   
    let pushIndex = [...this.state.displaytechAssumption, newRowData]
   
    this.setState({ displaytechAssumption: pushIndex });
    //console.log(this.state.displaytechAssumption)
  }

  addRow1 = () => {
    let newData = { description: "" }
    
let getSortNumberArray=this.state.displayGenAssumption.length>0 && this.state.displayGenAssumption.map(r=>{
  r.id=parseInt(r.id)
  return r
})
    //console.log("addRow100", newData)
    let MaxId = getSortNumberArray.length > 0 ?
    getSortNumberArray && getSortNumberArray.sort(
        function (a, b) {
          return parseFloat(b['id']) - parseFloat(a['id'])
        }
      )[0]['id']
      : 0;

    let newRowData = Object.assign({ id: (parseInt(MaxId) + 1).toString() }, newData)
    let pushIndex = [...this.state.displayGenAssumption, newRowData]
    this.setState({ displayGenAssumption: pushIndex });
    //console.log(this.state.displayGenAssumption)
  }

  addRow2 = () => {
    let newData = { description: "" }
    
let getSortNumberArray=this.state.displayOutOfScope.length>0 && this.state.displayOutOfScope.map(r=>{
  r.id=parseInt(r.id)
  return r
})
    //console.log("addRow100", newData)
    let MaxId = getSortNumberArray.length > 0 ?
    getSortNumberArray && getSortNumberArray.sort(
        function (a, b) {
          return parseFloat(b['id']) - parseFloat(a['id'])
        }
      )[0]['id']
      : 0;

    let newRowData = Object.assign({ id: (parseInt(MaxId) + 1).toString() }, newData)
    let pushIndex = [...this.state.displayOutOfScope, newRowData]
    this.setState({ displayOutOfScope: pushIndex });
    //console.log(this.state.displayGenAssumption)
  }
  rowUpdate = (newData, oldData) => {
    //console.log(newData, oldData)
    const index = this.state.displaytechAssumption.indexOf(oldData);
    this.setState({ displaytechAssumption: this.state.displaytechAssumption && this.state.displaytechAssumption.map(item => item === oldData ? item = newData : item) });
  }



  rowUpdate1 = (newData, oldData) => {
    //console.log(newData, oldData)
    const index = this.state.displayGenAssumption.indexOf(oldData);
    this.setState({ displayGenAssumption: this.state.displayGenAssumption.map(item => item === oldData ? item = newData : item) });
  }

  rowUpdate2 = (newData, oldData) => {
    //console.log(newData, oldData)
    const index = this.state.displayOutOfScope.indexOf(oldData);
    this.setState({ displayOutOfScope: this.state.displayOutOfScope.map(item => item === oldData ? item = newData : item) });
  }

  rowDelete = (oldData) => {
    //console.log("rowDelete", oldData)
    this.setState({ displaytechAssumption: this.state.displaytechAssumption.filter((item, i) => item != oldData) });
  }

  rowDelete1 = (oldData) => {
    //console.log("rowDelete", oldData)
    this.setState({ displayGenAssumption: this.state.displayGenAssumption.filter((item, i) => item != oldData) });
  }

  rowDelete2 = (oldData) => {
    //console.log("rowDelete", oldData)
    this.setState({ displayOutOfScope: this.state.displayOutOfScope.filter((item, i) => item != oldData) });
  }

  onSelectionChange = (evt, data) => {
    //console.log(evt, data)
  }

  rowSelectDel = (evt, data) => {
    //console.log(evt, data)
  }
  getChartData = () => {

    let labels = [];
    let datasetsData = [];
    let color = [];
    let generateDataSet = this.state.data;
    generateDataSet && generateDataSet.map(item => {
      color.push(item.sectionColor)
      datasetsData.push(item.sectionTotal)
      labels.push(item.sectionName)
    })
    let totalData = {
      labels: labels,
      datasets: [{
        data: datasetsData,
        backgroundColor: color,
        hoverBackgroundColor: color
      }]
    }

    this.setState({ chartData: totalData })
  }

  assumptionFieldChange = (e, id, type) => {
    if (type === "techDescription") {
      //console.log("assumptionFieldChange",e,id,type)
      let updatedObj = this.state.displaytechAssumption && this.state.displaytechAssumption.map(el => {
        if (el.id === id) {
          el.description = e.target.value

        }
        return el
      })
      this.setState({ displaytechAssumption: updatedObj })
      //console.log("estimationMetadata",this.state.estimationMetadata)
    }

    if (type === "genDescription") {
      console.log("genAssumptionFieldChange", e, id, type)
      let updatedObj = this.state.displayGenAssumption && this.state.displayGenAssumption.map(el => {
        if (el.id === id) {
          el.description = e.target.value

        }
        return el
      })
      this.setState({ displayGenAssumption: updatedObj })
      //console.log("estimationMetadata",this.state.estimationMetadata)
    }

    if (type === "outOfScopeDescription") {
      //console.log("assumptionFieldChange",e,id,type)
      let updatedObj = this.state.displayOutOfScope && this.state.displayOutOfScope.map(el => {
        if (el.id === id) {
          el.description = e.target.value

        }
        return el
      })
      this.setState({ displayOutOfScope: updatedObj })
      //console.log("estimationMetadata",this.state.estimationMetadata)
    }
  }
  // this.props.assumptionFieldChange(e,id,type)


  componentDidMount() {
    let mode = localStorage.getItem("mode");
    let getTotal = localStorage.getItem("updatedTotal")

    let displayWithBudget = this.state.displaySdlc && this.state.displaySdlc.map(item => {
      if (item.activity == 'Code Construction') {
        Object.assign(item, { itembudgeted: getTotal });

      }
      return item
    })
    //console.log("displayWithBudget",displayWithBudget)
    this.setState({ displaySdlc: displayWithBudget })
    if (mode === "edit") {
      let rowData = localStorage.getItem("rowData");
      let projectId = JSON.parse(rowData).projectId;

      this.setState({
        clientName: JSON.parse(rowData).clientName,
        projectName: JSON.parse(rowData).projectName
      })
      this.setState({ data: JSON.parse(rowData).sectionModel })
      this.setState({ allSectionTotal: JSON.parse(rowData).projectTotal })

     

      get(EDIT_ESTIMATE_BY_PROJECTID + projectId).then(res => {
        console.log(res, "Assumption")
        if(res.status==200 && res.data){
          let data=res.data;
          localStorage.setItem("rowData",JSON.stringify(data))

          this.setState({ displaytechAssumption: data.assumptions.technicalAssumption.map(r=>{
  r.id=parseInt(r.id)
  return r
}) })
          this.setState({ displayGenAssumption: data.assumptions.generalAssumption.map(r=>{
  r.id=parseInt(r.id)
  return r
}) })
         // console.log("displayOutOfScope c", JSON.parse(rowData) )
          this.setState({ displayOutOfScope: data.assumptions.outOfScope==null || data.assumptions.outOfScope==undefined ?[]:data.assumptions.outOfScope.map(r=>{
  r.id=parseInt(r.id)
  return r
}) })
        }


        this.getChartData()
      }
      )

    } else {
      // this.setState({ data: taskViewer[0].sectionModel })

      this.getChartData()
    }


  }
  onBack = (e) => {

    e.preventDefault();
    this.props.history.push("/home")
  }
  onSave = (e) => {
    e.preventDefault();


    if (this.props.clientName && this.props.projectName) {

      this.props.onSubmit()

      this.setState({ showMsg: false })
    } else {
      this.setState({ showMsg: true })
    }
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ showMsg: false })
  };


  toggleSidebar = (event) => {
    let key = `${event.currentTarget.parentNode.id}Open`;
    this.setState({ [key]: !this.state[key] });
  }

  render() {
    let TechAssumption = JSON.stringify(this.state.displaytechAssumption)
    let GenAssumption = JSON.stringify(this.state.displayGenAssumption)
    let outOfScope = JSON.stringify(this.state.displayOutOfScope)
    localStorage.setItem("displaytechAssumption", TechAssumption)
    localStorage.setItem("displayGenAssumption", GenAssumption)
    localStorage.setItem("displayOutOfScope", outOfScope)

    let assumptions = JSON.stringify(this.state.displaySdlc)
    let rightOpen = this.state.rightOpen ? 'open' : 'closed';

    return (
      <form id="assumptions">

        <div id='layout'>
          <div className='content'>
            <Grid container spacing={3}>

              <Grid item xs={12} sm={12}>

                <div style={{ textAlign: "left" }} className="sdlc-table">

                  <div style={{ marginBottom: "5px" }}>
                    <Snackbar open={this.state.showMsg} autoHideDuration={6000} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })}><Alert severity="error" >Client and product name are required fields!</Alert></Snackbar>

                    <ClientAndProductInput
                      onProjectandClientChange={this.props.onProjectandClientChange}
                      projectName={this.props.projectName}
                      clientName={this.props.clientName}
                      disabled={true} /> </div>
                  <div className="tabBox">
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                      <MatDatagrid
                        rows={this.state.displaytechAssumption}
                        className={this.abc}
                        columns={[
                          { title: 'Id', field: 'id', editable: 'never', sorting: true, defaultSort: 'asc' },
                          {
                            title: 'ASSUMPTIONS', field: 'description', sorting: false, render: (props) => {
                              //console.log(props)
                              return (<div>
                                <TextareaAutosize min={1} id={"description" + props.id} className={"form-control"} value={this.state["description" + props.id] || props.description} defaultValue={props.description} onChange={(e) => this.assumptionFieldChange(e, props.id, "techDescription")} onBlur={this.props.autoSaveOnBlur} />
                  
                              </div>)
                            }
                          }
                        ]}
                        title={<><button class="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" tabindex="0" type="button" title="Add"><span class="MuiIconButton-label"><span class="material-icons MuiIcon-root" aria-hidden="true" onClick={this.addRow}>add_box</span></span><span class="MuiTouchRipple-root"></span></button>TECHNICAL ASSUMPTIONS</>}
                        //  addRow={this.addRow}                  //to Add row
                        addRowPosition={"first"}              //"last"
                        rowUpdate={this.rowUpdate}
                        rowDelete={this.rowDelete}
                        onSelectionChange={this.onSelectionChange}
                        rowSelectDel={this.rowSelectDel}
                        editable={{
                          editable: true,
                          showEdit: false,
                          showDelete: true,
                          showAdd: false
                        }}
                        actionsColumnIndex={-1}
                        rowUpdate={this.rowUpdate}
                        localization={{
                          toolbar: {
                            searchPlaceholder: "Search Technical Assumptions"
                          },
                          pagination: { labelRowsSelect: "records" }
                        }}
                      //options={{searchText:"new search"}}

                      />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} md={12}>
                      <MatDatagrid

                        rows={this.state.displayGenAssumption}
                        columns= {[
                          { title: 'Id', field: 'id', editable: 'never', sorting: true, defaultSort: 'asc' },
                          {
                            title: 'ASSUMPTIONS', field: 'description', sorting: false, render: (props) => {
                              //console.log(props)
                              return (<div>
                                <TextareaAutosize min={1} id={"description" + props.id} className={"form-control"} value={this.state["description" + props.id] || props.description} defaultValue={props.description} onChange={(e) => this.assumptionFieldChange(e, props.id, "genDescription")} onBlur={this.props.autoSaveOnBlur} />
                  
                              </div>)
                            }
                          }
                        ]}
                        title={<><button class="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" tabindex="0" type="button" title="Add"><span class="MuiIconButton-label"><span class="material-icons MuiIcon-root" aria-hidden="true" onClick={this.addRow1}>add_box</span></span><span class="MuiTouchRipple-root"></span></button>GENERAL ASSUMPTIONS</>}
                        //addRow={this.addRow1}                  //to Add row
                        addRowPosition={"first"}              //"last"
                        rowUpdate={this.rowUpdate1}
                        rowDelete={this.rowDelete1}
                        onSelectionChange={this.onSelectionChange}
                        rowSelectDel={this.rowSelectDel}

                        editable={{
                          editable: true,
                          showEdit: false,
                          showDelete: true,
                          showAdd: false
                        }}
                        actionsColumnIndex={-1}

                        localization={{
                          toolbar: {
                            searchPlaceholder: "Search General Assumptions"
                          },
                          pagination: { labelRowsSelect: "records" }
                        }}
                      />

                      <MatDatagrid

                        rows={this.state.displayOutOfScope==null?[]:this.state.displayOutOfScope}
                        columns={[
                          { title: 'Id', field: 'id', editable: 'never', sorting: true, defaultSort: 'asc' },
                          {
                            title: 'Assumptions', field: 'description', sorting: false, render: (props) => {
                              //console.log(props)
                              return (<div>
                                <TextareaAutosize min={1} id={"description" + props.id} className={"form-control"} value={this.state["description" + props.id] || props.description} defaultValue={props.description} onChange={(e) => this.assumptionFieldChange(e, props.id, "outOfScopeDescription")} onBlur={this.props.autoSaveOnBlur} />
                  
                              </div>)
                            }
                          }
                        ]}
                        title={<><button class="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" tabindex="0" type="button" title="Add"><span class="MuiIconButton-label"><span class="material-icons MuiIcon-root" aria-hidden="true" onClick={this.addRow2}>add_box</span></span><span class="MuiTouchRipple-root"></span></button>Out of scope</>}
                        //addRow={this.addRow1}                  //to Add row
                        addRowPosition={"first"}              //"last"
                        rowUpdate={this.rowUpdate2}
                        rowDelete={this.rowDelete2}
                        onSelectionChange={this.onSelectionChange}
                        rowSelectDel={this.rowSelectDel}

                        editable={{
                          editable: true,
                          showEdit: false,
                          showDelete: true,
                          showAdd: false
                        }}
                        actionsColumnIndex={-1}

                        localization={{
                          toolbar: {
                            searchPlaceholder: "Search Out of scope"
                          },
                          pagination: { labelRowsSelect: "records" }
                        }}
                      />
                    </Grid>

                  </div></div>
                <div style={{ float: "right" }}>
                  <Button variant="contained" color="primary" onClick={this.onBack}>
                    Back to Dashboard
                  </Button>&nbsp;
                  <Button type="submit" variant="contained" color="primary" onClick={this.onSave}>
                    Save
                  </Button> &nbsp;

                </div>
              </Grid>

            </Grid>
          </div>
          <div id='right' >
            <div className='icon'
              onClick={this.toggleSidebar} >
              &equiv;
            </div>
            <div className={`sidebar ${rightOpen}`} >

              <EstimateViewer
                allSectionTotal={this.props.allSectionTotal}
                allStoryPoint={this.props.allSectionTotal * this.props.allSectionStoryPoint}
                label="BUILD STORYPOINT W/O F1" renderView={"points"} />

              <EstimateViewer allSectionTotal={this.props.allSectionTotalF1} allStoryPoint={this.props.allSectionTotalF1 * this.props.allSectionStoryPoint} label="BUILD STORYPOINT WITH F1" renderView={"points"} />
              {/* <ChartViewer data={this.props.chartData} /> */}


              <EstimateViewer
                allSectionTotal={this.props.allSectionTotal}
                allStoryPoint={this.props.allSectionTotal * this.props.allSectionStoryPoint}
                label="BUILD EFFORT W/O F1" renderView={"points&hours"} />
              <h5 className="average-total total-block" style={{ marginBottom: 0, color: '#fff' }}><b>Using F1 Saves <span className="percentH">{this.props.f1TotalPercentReduce ? Math.round(this.props.f1TotalPercentReduce) : 0} %</span></b></h5>
              <EstimateViewer allSectionTotal={this.props.allSectionTotalF1} allStoryPoint={this.props.allSectionTotalF1 * this.props.allSectionStoryPoint} label="BUILD EFFORT WITH F1" renderView={"points&hours"} />
              {/* <ChartViewer data={this.props.chartDataF1} /> */}



            </div>
          </div>
        </div>


      </form>
    )
  }
}

export default Assumptions;
import React from "react";
import './header.scss'

import F1HeaderNav from 'f1-header-navigation-react'
import logo from '../../assets/img/logoSqr.jpg'
import {Link} from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { Button } from "@material-ui/core";
import ModalPopup from 'f1-modal-react'
import {get, post} from '../../api/api';

import {REVOKE,REFRESH_TOKEN,GENERATE_TOKEN} from '../../api/baseURL'
class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      timeout:1000 * 7200,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      selectedProduct: 1,
      userId:""
    }
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    
  }

  ///Session idle
  
  _onAction(e) {
    //console.log('user did something', e)
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    //console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
 
  _onIdle(e) {
    //console.log('user is idle', e)
    const isTimedOut = this.state.isTimedOut
    if (isTimedOut) {
      
          // post(REVOKE,
          //   {
          //     "token":sessionStorage.getItem("token")
          //   }
          //   ).then(res=>{
          //     if(res.status===200){
            
          //     }
          //   })
            this.props.history.push('/')
            localStorage.clear();
            sessionStorage.clear();
    } else {
      this.setState({showModal: true})
      // this.idleTimer.reset();
      // this.setState({isTimedOut: true})
     
    }
    
  }

  ////////////////////
  logout = () => {  
    localStorage.clear();
    sessionStorage.clear();
    this.props.history.push("/");
    //window.localStorage.clear();
   
  }

  logoClick = () => {
    this.props.history.push("/home");
  }

  CustomBrand = ({}) => <div className="my-custom-brand">
  {/* <img src={logo} alt="" style={{width: '40px'}} />                 */}
</div>

onRedirect=()=>{
  this.props.history.push("/home")
  console.log("history",this.props.history.location.pathname)
}
onItemClick = (item, child_item) =>{
//console.log("Item Clicked!", item, child_item)

if(item.id==="estimate"){
  this.props.history.push('/estimate')
}else if(child_item && child_item.id==="logout"){
  window.onbeforeunload = function() {
    sessionStorage.clear();
    localStorage.clear();
 }
 this.props.history.push('/logout')
}else if(child_item&&child_item.id==="changePassword"){
  this.props.history.push('/changePassword')
}else{
  this.props.history.push('/home')
}
}

 brandImgStyle = {width: '90px'}
 navBarStyle = {backgroundColor: '#225cb2'}
 navBarSimpleStyle = {backgroundColor: '#A9A9A9'}
 
  headerConfig = {
    navBarStyle : this.navBarStyle,
    navBarClassName : 'my-custom-nav-bar',
    brandType : 'text', // text/image/component, required
    brandText: <span className="branding"  onClick={this.onRedirect}><img src={logo} style={{width:"35px", height:"35px"}}/><b>FULCRUM ONE ESTIMATOR</b></span>,
    brandLink: '',
    brandImgUrl: "", // in case of type === image
    brandClass: 'my-brand', // custom class for brand styling
    brandStyle: this.brandImgStyle, // style to be apply on brand component
    disableHambergerIcon: true, // default true
    onItemClick: this.onItemClick,
    menus:{
        right:window.location.href=="http://localhost:3000/#/home"?
        [
          {
            type: 'dropdown', // link/dropdown
            label: '',
            icon: 'user',
            className: 'custom-dropdown',         
            childs: [
              {
                label:' '+ localStorage.getItem("email"),
                href: '',
                className: 'custom-option',
                icon: 'user',
                id:"email"
              },
              {
                label: ' Logout',
                href: '',
                className: 'custom-option',
                icon: 'sign-out',
                id:'logout'
              }
            ], // in case of dropdowns
            is_active: false,
          },
          
        ]:[
       
         
          {
            type: 'link', // link/dropdown
            label: 'Dashboard',
            icon: '',
            href: '',
             // in case of dropdowns
            is_active: false,
            className: 'home',
            id:"home"
          },
          {
            type: 'dropdown', // link/dropdown
            label: '',
            icon: 'user',
            className: 'custom-dropdown',         
            childs: [
              {
                label:' '+ localStorage.getItem("email"),
                href: '',
                className: 'custom-option',
                icon: 'user',
                id:"email"
              },
              {
                label: ' Logout',
                href: '',
                className: 'custom-option',
                icon: 'sign-out',
                id:'logout'
              }
            ], // in case of dropdowns
            is_active: false,
          },
          
        ]
      }
  }
  onStay=()=>{
    this.setState({showModal:false})
  
    post(REFRESH_TOKEN,{
              "token":localStorage.getItem("token"),
              "userEmail": localStorage.getItem("email")
}).then(res=>{
              if(res.status===200){
                localStorage.setItem("token", res.data.accessToken);
                localStorage.setItem("refreshToken", res.data.refreshToken);
              }
            })
  }

  componentDidMount(){
    //localStorage.clear()
    this.validateuser();
  }
    


   validateuser = async () => {
    const res = await get(
      `https://sso-service.fulcrumone.net/validatetoken?Isazureadlogin=true`
    );
    if (res?.status === 200) {
    //  this.setState({userId:res.data.email})
    localStorage.setItem("email",res.data.email)
    localStorage.setItem("rolename", res.data.rolename)

    } else {

    }
  };
  render() {
    return (

      <div>
            
           <F1HeaderNav
            config={this.headerConfig}
            brandComponent={<this.CustomBrand />} // in case of type === component
          />
          
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout} /> 
               <ModalPopup
          show = {this.state.showModal}
          onHide={() => this.setState({showModal:false})}
          size = {"sm"}                                       // "lg","sm" or "xl"
          centered = {true}                                   // vertically center the Dialog in the window
          modelHeader = {"You Have Been Idle!"}                     // to show model header
          modelContent = {
            <div className="footerButtons">
              <p>You Will Get Timed Out.Want to stay on the page?</p>
              <Button onClick={()=>{
              this.setState({showModal:false})
               this.idleTimer.reset();
      this.setState({isTimedOut: true})
              this.props.history.push('/logout')
              localStorage.clear()
              sessionStorage.clear()
            }}>Logout</Button>
            <Button onClick={this.onStay}>Stay</Button>
            </div>}             // to show model content
             
          backdrop = {'static'}                               // true, false or 'static'
        
          />
       </div>
    );
  }
}


export default Header;

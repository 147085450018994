import React from "react";
import './home.scss';
import AddIcon from '@material-ui/icons/Add';
import Header from '../header/header'
import MatDatagrid from "f1-mat-data-grid-react";
import { Button } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

import { get, post, getDownloadData } from '../../api/api';
import { ESTIMATE_PROJECT_LIST_BY_EMAIL, ESTIMATE_PROJECT_LIST, DELETE_ESTIMATE, PROJECT_STATUS, STATUS_COUNT_BY_USER, CONVERT_TO_EXCEL, USER_ROLES, STATUS_COUNT } from '../../api/baseURL'
import Grid from '@material-ui/core/Grid';
import icnGraph from '../../assets/img/icnGraph.png'
import Paper from '@material-ui/core/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ModalPopup from 'f1-modal-react'
//import {Button} from 'react-bootstrap'
import FileSaver from 'file-saver';
import moment from 'moment'

import {GoogleAnalytics,GoogleAnalyticsURL,GoogleAnalyticsEvent} from 'f1-ga-react'
// import ReactGA from 'react-ga';
// ReactGA.initialize('G-SVM1GMWGM6');
// ReactGA.pageview(window.location.pathname + window.location.search);
//import { saveAs } from 'file-saver'
class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      modalShow: false,
      loading:false,
      displayProducts: [
        // {
        //   id: 1,
        //   ClientName: 'FD',
        //   ProjectName: 'F1'

        // },
        // {
        //   id: 2,
        //   ClientName: 'BAXA',
        //   ProjectName: 'Insurance'

        // },
        // {
        //   id: 3,
        //   ClientName: 'CS',
        //   ProjectName: 'CS2.0'
        // },

      ],
      columns: [
        { title: 'Project ID', field: 'projectId', draggable: false, width: '5%' },
        { title: 'Client Name', field: 'clientName', sorting: true, width: '20%' },
        { title: 'Project Name', field: 'projectName', sorting: false, width: '25%' },
        // moment().format("DD/MM/YYYY")
        {
          title: 'Created On', field: 'auditModel.createdDateTime', sorting: false, width: '15%', render: (props) => {
            let a = moment(props.auditModel.createdDateTime).format('DD/MM/YYYY');
            return <>{a}</>
          }
        },
        {
          title: 'Updated On', field: 'auditModel.updatedDateTime', sorting: false, width: '15%', render: (props) => {
            let a = moment(props.auditModel.updatedDateTime).format('DD/MM/YYYY');
            return <>{a}</>
          }
        },
        {
          title: 'Status', field: 'status', sorting: false, width: '5%', render: (props) => {
            //console.log(props)
            return (<div>
              <select id={"status" + props.projectId} value={this.state["status" + props.projectId] || props.status} onChange={(e) => this.statusChange(e, props.projectId)}>
                <option value="open" >Open</option>
                <option value="inProgress" >In Progress</option>
                <option value="pending" >Pending</option>
                <option value="completed" >Completed</option>
              </select>
            </div>)
          }
        },
        {
          title: 'Actions', width: '15%', field: '',
          render: rowData =>
            <span>
              <span class="material-icons iconClass editIcon" style={{color:'green'}} title="Edit" onClick={() => this.onEdit(rowData)}>
                edit
          </span>
              
              <span className="iconClass" onClick={() => this.exportToExcel(rowData)} title=" Export to Excel">
                <GetAppIcon className="icnExport" />
              </span>
              <span class="material-icons iconClass deleteIcon" style={{color:'#d40b0b'}} title="Delete" onClick={() => this.onclick(rowData)}>
                delete
          </span>

            </span>
        }],
      statusCount: []

    };
  }
  exportToExcel = (rowData) => {


    getDownloadData(CONVERT_TO_EXCEL, rowData).then(res => {
      var blob = new Blob([res.data]);
      FileSaver.saveAs(blob, 'Estimator.xlsx');
    }).catch(error => {
      //console.log(error)
    })

  }
  statusChange = (e, projectId) => {
    //console.log("statusChange", e.target.value)
    this.setState({ [e.target.id]: e.target.value })

    post(PROJECT_STATUS + "projectId=" + projectId + "&status=" + e.target.value).then(res => {
      this.getList()
      this.getStatusCount()
    })

  }
  onEdit = (id) => {
    //console.log("edit", id)
    localStorage.setItem("rowData", JSON.stringify(id));
    localStorage.setItem("mode", 'edit');
    this.props.history.push("/estimate");
  }

  onDelete = (id) => {
    ////console.log(id)
    this.setState({loading:true})
    let obj = JSON.parse(localStorage.getItem("rowData"))
    obj.isDeleted = true;
    //console.log("obj",obj)

    const str = localStorage.getItem("email");

const words = str.split('.');
//console.log(words[0],words[1]);
let firstName = words[0];
let lastName = words[1].split('@');
let lastNameF = lastName[0];

    GoogleAnalyticsEvent({category:'button',action:obj.projectId+" "+obj.projectName+" "+obj.clientName+" is deleted by "+firstName+" "+lastNameF+" on "+new Date()})
    //console.log(localStorage.getItem("email"))
    //console.log("gggg",obj)
    post(DELETE_ESTIMATE, obj).then(res => {
      this.setState({ modalShow: !this.state.modalShow })
      this.getList()
      this.getStatusCount()
      this.setState({loading:false})
    }
    )
  }
  logout = () => {
    this.props.history.push("/");
  }

  logoClick = () => {
    this.props.history.push("/home");
  }

  getList = () => {
    let role = localStorage.getItem('rolename')
    let email = localStorage.getItem('email')
    get(USER_ROLES + email).then(resp => {
      this.setState({loading:true})
      if (resp.data && resp.data.name === "Common") {
        get(ESTIMATE_PROJECT_LIST_BY_EMAIL + email).then(res => {
          //console.log("res", res)
          
          console.log("loading",this.state.loading)
          let filterIsDeleted = res.data && res.data.filter(item => item.isDeleted == false)
          this.setState({ displayProducts: filterIsDeleted })
          //  this.getStatusCount()
          this.setState({loading:false})
          
          console.log("DateData","loading",this.state.loading, res.data)
        }).catch(err=>{
          this.setState({loading:false})
        })
      } else {
        get(ESTIMATE_PROJECT_LIST).then(res => {
          //console.log("res", res)
          let filterIsDeleted = res.data && res.data.filter(item => item.isDeleted == false)
          this.setState({ displayProducts: filterIsDeleted })
          // this.getStatusCount()
          this.setState({loading:false})
        }).catch(err => {
          this.setState({loading:false})
        }
        )
      }
    })


  }

  getStatusCount = () => {
    let role = localStorage.getItem('rolename')
    let email = localStorage.getItem('email')
    get(USER_ROLES + email).then(resp => {

      if (resp.data && resp.data.name === "Common") {
        get(STATUS_COUNT_BY_USER + email).then(res => {
          //console.log("statusCount", res)
          this.setState({ statusCount: res.data.statusCountModel })
        })
      } else {
        get(STATUS_COUNT).then(res => {
          //console.log("statusCount", res)
          this.setState({ statusCount: res.data })
        })
      }
    })
  }

  componentDidMount() {
    // console.log("this.props.history", this.props)
    // console.log('accountIdentifier'+this.props.user.account.accountIdentifier)

    GoogleAnalytics('UA-189494357-1');  //your GA code 
    this.getList()
    this.getStatusCount()
  }
  addNew = () => {
    localStorage.setItem("rowData", {})
    localStorage.setItem("mode", "add")
    this.props.history.push("/estimate")
  }

  onclick = (id) => {
    //console.log("fdfsf",id)
    localStorage.setItem("rowData", JSON.stringify(id));
    this.setState({ modalShow: !this.state.modalShow })
    //console.log(this.state.modalShow)
  }
  onEnter = () => {
    //console.log("onenter")
  }
  onExit = (e) => {
    //console.log("onexit")
  }
  handleClose = () => {
    this.setState({loading:false});
  };
  onChangePage = (page) => {
    console.log("onChangePage", page)
  }
  render() {
    return (

      <div className="main-container homePage">
        {/* <Backdrop 
        className="backdrop" 
        open={this.state.loading} onClick={this.handleClose}>
        <CircularProgress color="success" />
      </Backdrop> */}
        
        <Header history={this.props.history} />
        <div className="analytics-section">
          <Grid container spacing={5}>

            <Grid item sm={3} md={3} >
              <Paper>
                <div className="analytics">
                  <span><img className="icn" src={icnGraph} /></span>
                  <span>{this.state.statusCount && this.state.statusCount.openCount}</span>
                  <span>Open Projects</span>
                </div>
              </Paper>
            </Grid>
            <Grid item sm={3} md={3} >
              <Paper>
                <div className="analytics">
                  <span><img className="icn" src={icnGraph} /></span>
                  <span>{this.state.statusCount && this.state.statusCount.inProgressCount}</span>
                  <span>InProgress Projects</span>

                </div>
              </Paper>
            </Grid>
            <Grid item sm={3} md={3} >
              <Paper>
                <div className="analytics">
                  <span><img className="icn" src={icnGraph} /></span>
                  <span>{this.state.statusCount && this.state.statusCount.pendingCount}</span>
                  <span>Pending Projects</span>

                </div>
              </Paper>
            </Grid>
            <Grid item sm={3} md={3} >
              <Paper>
                <div className="analytics">
                  <span><img className="icn" src={icnGraph} /></span>
                  <span>{this.state.statusCount && this.state.statusCount.completedCount}</span>
                  <span>Completed Projects</span>

                </div>
              </Paper>
            </Grid>
          </Grid>


        </div>
        
        <MatDatagrid
          rows={this.state.displayProducts}
          columns={
            [
              { title: 'Project ID', field: 'projectId', draggable: false, width: '10%' },
              { title: 'Client Name', field: 'clientName', sorting: false, width: '10%' },
              { title: 'Project Name', field: 'projectName', sorting: false, width: '20%' },
              {
                title: 'Created By', field: 'auditModel.createdBy', sorting: false, width: '20%', render: (props) => {

                  let indexAt = props.auditModel.createdBy;

                  return <span style={{ textTransform: "lowercase" }}>{props.auditModel.createdBy}</span>
                  //  <span style={{ textTransform: "capitalize"}}>{  props.auditModel.createdBy.slice(0,indexAt).replace("."," ")}</span>
                }
              },
              // moment().format("DD/MM/YYYY")
              {
                title: 'Created On', field: 'auditModel.createdDateTime', sorting: false, width: '10%', render: (props) => {
                  let a = moment(props.auditModel.createdDateTime).format('DD/MM/YYYY');
                  return <>{a}</>
                }
              },
              {
                title: 'Updated On', field: 'auditModel.updatedDateTime', sorting: false, width: '10%', render: (props) => {
                  let a = moment(props.auditModel.updatedDateTime).format('DD/MM/YYYY');
                  return <>{a}</>
                }
              },
              {
                title: 'Status', field: 'status', sorting: false, width: '10%', render: (props) => {
                  //console.log(props)
                  return (<div>
                    <select id={"status" + props.projectId} value={this.state["status" + props.projectId] || props.status} onChange={(e) => this.statusChange(e, props.projectId)}>
                      <option value="open" >Open</option>
                      <option value="inProgress" >In Progress</option>
                      <option value="pending" >Pending</option>
                      <option value="completed" >Completed</option>
                    </select>
                  </div>)
                }
              },

              {
                title: 'Actions', width: '10%', field: '',sorting:false,
                render: rowData =>
                  <span>
                    <span class="material-icons iconClass" style={{color:'green'}} title="Edit" onClick={() => this.onEdit(rowData)}>
                      edit
                </span>
                   
                    <span className="iconClass" onClick={() => this.exportToExcel(rowData)} title=" Export to Excel">
                      <GetAppIcon className="icnExport" />
                    </span>
                    <span class="material-icons iconClass" style={{color:'#d40b0b'}} title="Delete" onClick={() => this.onclick(rowData)}>
                      delete
                </span>

                  </span>
              }]
          }
          title={<><h4 className="gridHeading"></h4> <Button variant="contained" color="primary" onClick={this.addNew}><AddIcon className="icnR" />Create Estimate</Button>
          </>}
           props={{labelRowsSelect:"records"}}
          paging={true}
          pageSize={12}
          paginationType={"stepped"}  
          localization={{            
            toolbar: {
            searchPlaceholder: "Search Projects"
            },
            pagination:{labelRowsSelect:"records"}
            }}
            
        
         
        />
        
        <ModalPopup
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
          size={"sm"}                                       // "lg","sm" or "xl"
          centered={true}                                   // vertically center the Dialog in the window
          modelHeader={"Delete Estimate"}                     // to show model header
          modelContent={<p>You're about to delete an estimation. This is  irresversible  process. <br/>You may not be retrive estimate back. <b>Are you sure you want to delete it?</b></p>}             // to show model content
          modelFooter={<><Button style={{backgroundColor:"red", color:"white"}} onClick={this.onDelete}>YES</Button><Button onClick={() => this.setState({ modalShow: false })} style={{backgroundColor:"green", color:"white", margin:"5px"}}>NO</Button></>}             //// to show model footer
          backdrop={'static'}                               // true, false or 'static'
          scrollable={true}                                 //boolean
          onEnter={this.onEnter}                            //Callback fired before the Modal transitions in
          onExit={this.onExit}                              //Callback fired right before the Modal transitions out
          closeButtonShow={true}
          parentClassName="ModalBackground"
          headerClassName="ModalHeaderBackground"
          bodyClassName="ModalBodyBackground"
          footerClassName="ModalFooterBackground"
        />

<Backdrop open={this.state.loading} style={{ zIndex: 1,
    color: '#fff',}}>
  <CircularProgress color="inherit" />
</Backdrop>
      </div>
    );
  }
}

export default Home;

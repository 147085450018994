import React from 'react';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import { faPlus, faArrowsAlt, faTimes, faPaintBrush, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Draggable } from 'react-smooth-dnd';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

class TableSection extends React.Component {
    constructor() {
        super();
        this.state = {

        }
    }
    onCollapse = (e, id) => {
        this.setState({ [e.target.id]: !this.state[e.target.id] })
        //console.log(e.target.value, id, this.state[e.target.id])
    }
    onPaint = (e, id) => {
        this.setState({ [e.target.id]: !this.state[e.target.id] })
        //console.log(e.target.value, id, this.state[e.target.id])
    }
    // componentWillMount(){
    //     this.setState({["sectionCollapse"+this.props.sectionData.id]:true})
    // }
    render() {
        const { addSection, taskData, color, sectionData, changeColor, hideShowPallete, sectionOnchange, onDeleteSection, onDropTask } = this.props;
        let id = sectionData.sectionId;
        return (
            <Draggable key={id}>
                <Table className={"table section color-" + color} >
                    <tbody>
                        {/* Story input */}
                        <tr>
                            <th>
                                <span className="section-name">
                                    <input type="text" id={"section" + id} title={this.props["section" + id] || sectionData.sectionName} value={this.props["section" + id] || sectionData.sectionName} placeholder="Epic" onChange={(e) => sectionOnchange(e, id, "sectionName")} onBlur={this.props.autoSaveOnBlur}/>

                                </span>
                            </th>
                            <th>
                                <span className="usingf1new">Use F1?</span>
                            </th>
                            <th>
                                <span className="section-total"><span> Storypoint (w/o F1) <span style={{fontWeight:'bold'}}>{sectionData.sectionTotal}</span></span>
                                </span>
                            </th>
                            <th>
                                <span className="section-total" style={{ textAlign: "right" }}><span>Storypoint (with F1)  <span style={{fontWeight:'bold'}}>{sectionData.f1Efforts}</span></span>

                                </span>
                            </th>
                            <th>
                                <span className="action-icons" >
                                    <span className="add-section action-icon" title="" style={{color:'green'}} onClick={(e) => addSection(e, id)}>  <FontAwesomeIcon icon={faPlus} />&nbsp;</span>
                                    <span className="move move-section action-icon drag-handle" title="" > <FontAwesomeIcon icon={faArrowsAlt} />&nbsp;</span>
                                    <span className="delete action-icon" title="" style={{color:'#d40b0b'}} onClick={(e) => onDeleteSection(e, id)}> <FontAwesomeIcon icon={faTimes} />&nbsp;</span>
                                    <span className="color change-color action-icon icon-brush" title="" onClick={(e) => hideShowPallete(e, id)}>
                                        <FontAwesomeIcon icon={faPaintBrush} id={"paint" + id} onClick={(e) => this.onPaint(e, id)} />&nbsp;&nbsp;
                                    {this.state["paint" + id] ?
                                            <span className="colorblock show">
                                                <span className="red" onClick={(e) => changeColor(e, id, "red")}></span>
                                                <span className="turquoise current" onClick={(e) => changeColor(e, id, "turquoise")}></span>
                                                <span className="yellow" onClick={(e) => changeColor(e, id, "yellow")}></span>
                                                <span className="green" onClick={(e) => changeColor(e, id, "green")}></span>
                                                <span className="purple" onClick={(e) => changeColor(e, id, "purple")}></span>
                                                <span className="blue" onClick={(e) => changeColor(e, id, "blue")}></span>
                                            </span> : ""}
                                    </span>
                                </span>
                            </th>
                            <th>
                                <span className="section-expand-collapse" >
                                    <FontAwesomeIcon id={"sectionCollapse" + id} onClick={(e) => this.onCollapse(e, id)} icon={this.state["sectionCollapse" + id] ? faChevronDown : faChevronUp} onClick={(e) => this.onCollapse(e, id)} />
                                </span>
                            </th>
                        </tr>
                        <tr>
                            <td colSpan="6" className="tasks no-padding ui-sortable" style={{ padding: 0 }}>
                                {!this.state["sectionCollapse" + id] ?
                                    taskData : ""}
                            </td>
                        </tr>
                        {/* Task input */}

                    </tbody>
                </Table>
            </Draggable>
        )
    }
}

export default TableSection;
import React from 'react';
import {Pie} from 'react-chartjs-2';
const options = {
  maintainAspectRatio: false,
  responsive: false,
  legend: {
    position: 'left',
    labels: {
      boxWidth: 10
    }
  }
}
class ChartViewer extends React.Component{

  render() {
    return (
      <div>
      
        <Pie data={this.props.data}  options={options}/>
     
      </div>  
    );
  }
}
export default ChartViewer;
import React from "react";
import './login.scss';
import '../../App.css';
import Header from '../header/loginHeader';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
//import { Link } from 'react-router-dom';
import { post, get } from '../../api/api';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import microsoftlogo from '../../assets/img/microsoftlogo.png'

//import ReactGA from 'react-ga';
import { GENERATE_TOKEN, USER_ROLES } from '../../api/baseURL'
// import SliderImage1 from '../../assets/estimation_slider1.jpg'
// import SliderImage2 from '../../assets/estimation_slider2.jpg'
// import SliderImage3 from '../../assets/estimation_slider3.jpg'
//import { loginWithAd } from "../../api/api";

import Signin from "f1-signin-form-react";

import { authProvider } from '../../authProvider';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import LoginWithAD from "./loginWithAD";
//import { Button } from "react-bootstrap";
class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      email: '',
      password: '',
      current: { id: 1, email: "EstimatorAdmin", password: "123" },

      formData: [
        { "key": "email", "type": "email", "disabled": false, "id": "username", "icon": "user", "label": "User Name", "validators": [{ "name": "required", "value": true }] },
        { "key": "password", "type": "password", "disabled": false, "id": "password", "icon": "user", "label": "Password", "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 2 }, { "name": "maxLength", "value": 10, "validationMessage": "Maximum 10 digits allowed." }] },
      ],
      allowAd: false,

    };
  }

  static displayName = Login.name;

  onChangeHandler = (event, key) => {
    console.log("On change triggered!!!!!!!", event.target.value, key);
  }

  submitForm = (event, data) => {
    post(GENERATE_TOKEN,
      {
        "email": data.formData.email,
        "password": data.formData.password,
        "clientID": "estimator"
      }
    ).then(res => {

      if (res.status === 200) {
        console.log("res",res)
        localStorage.setItem("token", res.data.tokenGeneration.accessToken);
        localStorage.setItem("refreshToken", res.data.tokenGeneration.refreshToken);
        localStorage.setItem("email", data.formData.email);

        get(USER_ROLES + data.formData.email).then(resp => {
          // console.log("resp",resp)
          localStorage.setItem("rolename", resp.data.name)
        })
        // this.setState({show:true})
        this.props.history.push("/home")
      }
    }).catch(error => {
      //console.log(error,"nnn","Invalid credentials.")

      this.setState({ show: true })


    })
    //console.log("this is form data", data);     
    // set errors to state here  


  };

  rememberMe = (value) => {
    //console.log(value.formData.rememberme);       
  };

  forgotPass = (value) => {
    // console.log(value.formData);      
    this.props.history.push('/forgotPassword')
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ show: false })
  };

  onAdClick = () => {
    console.log(this.props.authenticationState)
  }

  adLogin = () => {

    // console.log('hii');
    // this.setState({allowAd:true})
    // if(this.props.ad && this.props.ad.authenticationState==="Authenticated"){

    // }
  }
  getToken = (token, info) => {
    console.log(token, info)
    if (token == 'Authenticated') {
      localStorage.setItem("token", info.jwtIdToken);
      localStorage.setItem("email", info.account.userName);
      // localStorage.setItem("email", info.account.userName);
      localStorage.setItem("email", info.account.userName);
      console.log(token, info, info.account.userName)
      this.props.history.push('/home')
    }
  }

  componentDidMount(){
    //localStorage.clear()
    let isAuth = localStorage.getItem('token')?true:false;
    if (isAuth){
      this.props.history.push('/home')
    }
  }
 
  // loginAD = async() => {
  //   const res = await loginWithAd();
  //   if(res.status === 200)
  //   {
  //     this.setState({url:res.data})
  //     // return res.data
  //   }
  // }
  render() {
    //console.log("test2",this.props.user);    
    return (
      <div className="main-container loginPage">
        <Header history={this.props.history} />
        <div className="mainContainer">
          <div className="LoginContainer">
            <div className="loginWrapper">
              <div className="innerContainer">
                <h1>Login</h1>
                 {/* <Signin
                Email={true}
                UserName={false}
                RememberMe={true}
                Title=""
                titleAlign="left"
               
                SubmitButtonText="Sign in"
                LableUsername="Username"
                PlaceholderUsername="Enter your Username"
                onSubmit={this.submitForm}
                design="material" // bootstrap
                materialOutline={true}
                disableSubmit={false}
                // onBlur={this.onBlur}
                validationEvent="onchange" // onchange/blur
              />  */}
                <p id='loginText'>For Faster & Easier Estimates! </p>
               
                {/* <p align="center" onClick={this.loginAD} style={{margin:'6rem 0 0', cursor:'pointer'}} disabled={true}><img onClick={this.submitForm} src={AdLogin} /></p>
               */}
                <div> 
              <LoginWithAD/></div>
              </div>
              <div className="outerContainer">
                <p style={{margin:0, 'fontWeight':'600', fontSize:'0.75em'}} className="copyright">Copyright © 2021 | All Rights Reserved. Powered by Fulcrum Digital</p>
              </div>
            </div>
          </div>
          <div className="descriptionDiv">
            <div style={{display:'flex', flexDirection:'column', textAlign:'start', marginTop:'40px'}} className="MultiLine">
              <h1 style={{fontSize:'62px'}} id="EstimationsText">Estimations</h1>
              <h1 style={{fontSize:'62px'}} id="EstimationsText">Simplified.</h1>
            </div>
            <span style={{width:'100%'}}>
              An offering from 
              <br/>
              office of technology and innovation
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;

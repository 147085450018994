import React from "react";
import './register.scss';
import '../../App.css';
import DynamicForm from 'f1-dynamic-form-react';
import Header from '../header/loginHeader';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { post } from '../../api/api';
import { CREATE_COMMON_USER } from '../../api/baseURL';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import {Link} from 'react-router-dom';

class Register extends React.Component {
  constructor() {
    super();
    this.state = {
      showMsg:false,
      message:"",
     
      current: { id: 1 },

      formData: [
        // { "key": "name", "disabled": false, "id": "name", "icon": "user", "label": "Name", "validators": [{ "name": "required", "value": true }] },
        { "key": "email", "type": "email", "disabled": false, "id": "email", "icon": "user", "label": "Email", "validators": [{ "name": "required", "value": true },{ "name": "isEmail", "value": true }] },
        // { "key": "companyName", "disabled": false, "id": "companyName", "icon": "user", "label": "Company Name", "validators": [{ "name": "required", "value": true }] },
       
        { "key": "password", "type": "password", "disabled": false, "id": "password", "icon": "user", "label": "Password", "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 2 }, { "name": "maxLength", "value": 10, "validationMessage": "Maximum 10 digits allowed." }] },
       
        // { "key": "passwordAgain", "type": "password", "disabled": false, "id": "passwordAgain", "icon": "user", "label": "Retype Password", "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 2 }, { "name": "maxLength", "value": 10, "validationMessage": "Maximum 10 digits allowed." }] },
      ]

    };
  }
  componentDidMount(){
    let colors = ['#87d5d473', '#ffd0976e', '#72c6fa6e', '#8000807d', "#28a74559","#dc35456e"];
    let selectedColor = colors[Math.floor(Math.random() * colors.length)]
    document.getElementsByTagName("body")[0].style.backgroundColor=selectedColor;
  }

  onChangeHandler = (event, key) => {
   // console.log("On change triggered!!!!!!!", event.target.value, key);
    //this.setState({key:event.target.value})
    //console.log("state values",this.state)
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ showMsg: false })
  };

  submitForm = (event, value) => {

   // console.log("check",value,event);
    let schema = {
      "email": event.email,
      "password": event.password
    }
    var response = event.email.substring(event.email.indexOf("@"));
if(response=="@fulcrumdigital.com"){
  post(CREATE_COMMON_USER, schema).then(res => {
    this.setState({message:"User registered", showMsg:true})
    setTimeout(()=>{
      this.props.history.push("/")
    },500) 
    
  }).catch(err =>{
    if(err.status == 400){
      this.setState({message:"Email already in use", showMsg:true})
    }else{

    }
  })
 
}else{
  this.setState({message:"Please use FD email id", showMsg:true})
}
    

  };
  
  render() {
    return (
      <div className="main-container registerPage">
        <Header history={this.props.history}/>
        <Grid container justify="center"
  alignItems="left" >
          <Grid item sm={6} md={4} direction="column">
          <Paper className={""} style={{textAlign:"left"}}>
              <DynamicForm
          key={this.state.current.id}
          className="form horizontal"
          title="Register"
          buttonSubmit="Submit"
          // defaultValues={this.state.current}
          model={this.state.formData}
          onSubmit={this.submitForm}
          disableSubmitOnInValid={false}
          customValidators={this.customValidators}
          displayInformation={true}
          displaydetails="For Faster & Easier Estimates!"
          design="bootstrap" // material/bootrstap/advanceMaterial/advanceBootstrap
          materialOutline={true}
          extErrors={this.state.extErrors}
          onChange={this.onChangeHandler}
        />
       <p style={{textAlign:"center",padding:"5px",fontSize:"15px"}}><Link to="/login"> ALREADY REGISTERED? LOGIN HERE!</Link></p>
        
            </Paper>  
          </Grid>
        </Grid>
        <Snackbar open={this.state.showMsg} autoHideDuration={500} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })}><Alert severity="error" >{this.state.message}</Alert></Snackbar>
      
      </div>
    );
  }
}

export default Register;

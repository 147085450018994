import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';

import Datatable from 'react-bs-datatable';
import './sprintMatrix.scss'
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import MatDatagrid from "f1-mat-data-grid-react";
import ClientAndProductInput from '../../components/clientAndProductInput/clientAndProductInput'

import Accordian from 'f1-accordian-react'
import "font-awesome/css/font-awesome.min.css"

class SprintMatrix extends React.Component{
    constructor(){
    super()
    this.state = {
        defaultSort: false,   ///asc
        orderBy:3,
        refresh:true,
        rows: [],
       
        columns: [

        ]
      }
    
    }

        customHeaderHtml = React.createElement('div', { className: 'heading icon' }, <span>Custom Sprint Matrix<i className="fa fa-caret-down icon1"></i></span> );
      triggerWhenOpen = React.createElement('div', { className: 'heading icon' }, <span>Custom Sprint Matrix<i className="fa fa-caret-up icon1"></i></span> );
      triggerStyle = {color: 'yellow'}


      customHeaderHtmlF1 = React.createElement('div', { className: 'heading icon' }, <span>F1 Sprint Matrix<i className="fa fa-caret-down icon1"></i></span> );
      triggerWhenOpenF1 = React.createElement('div', { className: 'heading icon' }, <span>F1 Sprint Matrix<i className="fa fa-caret-up icon1"></i></span> );
      triggerStyle = {color: 'yellow'}
    onChange = () => {

    }
    addStories = () => {
        //let getColumns = this.state.columns.map(el => el.field)
    
        this.setState({ rows: [...this.state.rows, { moduleName: '', complexCityPoints: 0, efforts: 0,  elapsedTime: 0}] })
      }
      addSprint = () => {
        let columnsLength = this.state.columns.length+1;
    
        this.setState({ columns: [...this.state.columns, { field: "sprint" + columnsLength, title:<>{ "Sprint" + columnsLength }<span onClick={()=>alert("delete column")} style={{position: "absolute",
            left: "70px"}}>Delete</span></>,render:(props)=>{
            return <input type="checkbox" onChange={this.onChange(props)} defaultValue={props.sprint1}/>
                        }}] })
     
        this.setState({
          refresh:true,
        })

        
     //   this.gridFunction([...this.state.columns, { field: "sprint" + this.state.columns.length, title: "Sprint" + this.state.columns.length }])
      }

//   gridFunction=(addCol)=>{

//           return  <MatDatagrid
//           rows={this.state.rows}
//           columns={addCol}
//           title={<span>ESTIMATION METADATA<span  style={{color:"red"}}>*</span></span>}
//           rowUpdate={this.props.rowUpdate}
//           onSelectionChange={this.onSelectionChange}
//           search={false}
//           editable={{
//               editable: true,
//               showEdit: false,
//               showDelete: false,
//               showAdd: false
//           }}
       
// />
//       }

componentWillMount(){
    let filterTask=this.props.data && this.props.data.map(item=>{
        item.taskModel.map(el=>{
            return el
        })
    })
    this.setState({rows:filterTask})
    console.log(filterTask,this.props.data)    
}



onChangeMatrixHandler = (e) =>{
  this.props.onChangeMatrix(e);
}
    render(){
        
        return(
            <div>
               <Snackbar open={this.props.showMsg} autoHideDuration={6000} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })}><Alert severity="error" >Client and product name are required fields!</Alert></Snackbar>
                <ClientAndProductInput
                  onProjectandClientChange={this.props.onProjectandClientChange}
                  projectName={this.props.projectName}
                  clientName={this.props.clientName}
                  disabled={true}
                />
                <div className="row matrixHeadDiv">
                  
                <div className="col-sm-7">
                      <label className="labelDiv">Sprint Capacity</label>
                      <input type="number" className="form-control matrixInput" style={this.props.showCapacityAlert?{border:"1px solid red"}:{}} onChange={this.onChangeMatrixHandler} value={this.props.sprintMatrixState} placeholder="Capacity" min="0"/>
                      <div className="textFullDiv">
                      <b>OR</b> as per required sprints
                      </div>
                      <input type="number" className="form-control noinputBx" onChange={this.props.onChangeSprint} value={this.props.sprintValue} min="0" placeholder="No. of Sprints" />
                    </div>
                    
                    <div className="col-sm-5 text-left">
                    
                        <button type="button" onClick={this.props.generateSprintMatrix} disabled={this.props.sprintMatrixState?false:true} className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary">Generate Sprint Matrix</button>&nbsp;&nbsp;
                        <button type="button" onClick={this.props.onSubmit} className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"><SaveIcon/> &nbsp; Save Matrix</button>&nbsp;&nbsp;
                        <button type="button" title="Reset Matrix" onClick={this.props.generateSprintMatrix} className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"><RefreshIcon/></button>
                        
                    </div>
                </div>
                {this.state.refresh&&<>
                <div className="grid-main-div">
                <Accordian 
              trigger={this.customHeaderHtml}
              onOpen={this.onOpenHandler}
              onClose={this.onCloseHandler}
              triggerWhenOpen={this.triggerWhenOpen}
              triggerStyle={this.triggerStyle}
              content={
                <div className="grid-main-div">
                  <div className="widHeightDivScroll">
                  <div className='sprintEditableCapacity'>
                  {
                    this.props.sprintColumns.map((column, index)=>{
                      if(column.field == "taskName" || column.field == "sprintMatrix"){
                        return <React.Fragment></React.Fragment>
                      }
                      else{
                        return <input onChange={(e)=>this.props.onChangeCustomizedCapacity(e, column.field, index)} key={"CustomizedCapacity"+column.field} id={column.field} value={this.props.customizedSprintCapacity[column.field]} className="sprintEditableCapacity" type="text"></input>
                      }
                      
                    })
                  }
                  </div>
               {this.props.allSectionTotal>1? <Datatable 
                borderable tableHeaders={this.props.sprintColumns}
                 tableBody={this.props.sprintMatrixData} />:<p>Please provide storypoints in Build efforts tab</p>}
                 </div></div>
              }
              transitionTime={600}
              transitionCloseTime={200}
              easing="ease-in"
              open={true}
              className="my-custom-collapsible"
              triggerDisabled={false} 

             />

<Accordian 
              trigger={this.customHeaderHtmlF1}
              onOpen={this.onOpenHandler}
              onClose={this.onCloseHandler}
              triggerWhenOpen={this.triggerWhenOpenF1}
              triggerStyle={this.triggerStyle}
              content={
                <div className="grid-main-div">
                  <div className="widHeightDivScroll">
                  {this.props.allSectionTotal>1? <Datatable 
                borderable tableHeaders={this.props.sprintColumnsF1}
                 tableBody={this.props.sprintMatrixDataF1} />:<p>Please provide storypoints in Build efforts tab</p>}
                 </div></div>
              }
              transitionTime={600}
              transitionCloseTime={200}
              easing="ease-in"
              open={true}
              className="my-custom-collapsible"
              triggerDisabled={false} 

             />
                 </div>
                 <br/>
                
                 </>
                 
    }
                  
            
            </div>
        )
    }
}

export default SprintMatrix;
export const BASE_URL_JWT = 'https://sso-service.fulcrumone.net';

 //export const BASE_URL_JWT = 'https://estimator-jwt.fulcrumone.net';
//  export const BASE_URL_JWT = 'https://staging-adhoc-estimator-jwt.fulcrumone.net';
 export const GENERATE_TOKEN = BASE_URL_JWT+'/api/generateToken';
 export const REFRESH_TOKEN = BASE_URL_JWT+'/api/token/refresh';
 export const USER_ROLES=BASE_URL_JWT+'/api/GetUserRole?email=';
 export const CREATE_COMMON_USER = BASE_URL_JWT+'/api/Users';
 export const CHANGE_PASSWORD=BASE_URL_JWT+'/api/ChangePassword';
 export const GET_COLLABORATOR=BASE_URL_JWT+'/api/GetAllUser?role=';
export const REVOKE=BASE_URL_JWT+'/api​/token​/revoke';
 //export const BASE_URL_ESTIMATE = 'https://localhost:44359';
 
// export const BASE_URL_ESTIMATE = 'https://estimator-service.fulcrumone.net';
 
//  export const BASE_URL_ESTIMATE = 'https://staging-adhoc-estimator-svc.fulcrumone.net';
 export const BASE_URL_ESTIMATE = 'https://estimator-service.fulcrumone.net';
//  https://estimator-service.fulcrumone.net/api/GetStatusCount
 export const ESTIMATE_PROJECT_LIST = BASE_URL_ESTIMATE+'/api/GetEstimate';
 export const ESTIMATE_PROJECT_LIST_BY_EMAIL = BASE_URL_ESTIMATE+'/api/GetEstimatesCreatedBy/email?email=';
 export const EDIT_ESTIMATE_BY_PROJECTID = BASE_URL_ESTIMATE+'/api/GetEstimate/ProjectId?projectId=';
 export const SHARE_ESTIMATE_BY_PROJECTID = BASE_URL_ESTIMATE+'/api/GetEstimatesForShareableLink/ProjectId?projectId=';
 export const PROJECT_STATUS = BASE_URL_ESTIMATE+ '/api/ChangeStatus/ProjectId/status?';
 export const STATUS_COUNT = BASE_URL_ESTIMATE+ '/api/GetStatusCount'
 export const STATUS_COUNT_BY_USER = BASE_URL_ESTIMATE+ '/api/GetEstimatesByCreatedByCount?email='
 
export const EDIT_ESTIMATE=BASE_URL_ESTIMATE+'/api/EditEstimate';
export const ADD_ESTIMATE=BASE_URL_ESTIMATE+'/api/AddEstimate';
export const GET_MAX_PROJECT_ID=BASE_URL_ESTIMATE+'/api/GetMaxProjectId';
export const DELETE_ESTIMATE=BASE_URL_ESTIMATE+'/api/DeleteEstimate';
 export const BASE_URL_ESTIMATE_EXPORT_EXCEL = 'https://estimatorexcel.azurewebsites.net';
export const CONVERT_TO_EXCEL=BASE_URL_ESTIMATE_EXPORT_EXCEL+'/api/ConvertToExcel';

export const  LOGIN_WITH_MICROSOFT =`https://sso-service.fulcrumone.net/createaccesstoken/estimator`

import React from 'react';
import Grid from '@material-ui/core/Grid';
import './estimate.scss';
import '../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import TaskViewer from '../../components/TaskViewer/TaskViewer'
import Header from '../../components/header/header'
import SDLC from '../../components/sdlc/sdlc'
import TabPanel from 'f1-tab-panel-react'
import Assumptions from '../../components/assumptions/assumptions';
import Summary from '../../components/devlopmentEstimate/summary';
import Collaborator from '../../components/collaborators/collaborator';
import SprintMatrix from '../../components/sprintMatrix/sprintMatrix'
import { get, post } from '../../api/api';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { EDIT_ESTIMATE_BY_PROJECTID, EDIT_ESTIMATE, ADD_ESTIMATE, GET_MAX_PROJECT_ID } from '../../api/baseURL'
import ModalPopup from 'f1-modal-react';
import { Button } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

// import GetAppIcon from '@material-ui/icons/GetApp' ;

import taskViewer from "../../components/TaskViewer/newJSON.json"
export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

// const classes = useStyles();
class Estimate extends React.Component {
  constructor() {
    super();
    
    this.state = {
      openDisclaimer:true,
      customizedSprintCapacity: {},
      uniqueShareableLink: '',
      taskMax: 0,
      showCapacityAlert:false,
      storyPoint: 8,
      sprintMatrixState: 0,
      showModal: false,
      dataForSprintMatrixCalculation: [],
      sprintColumns: [{
        field: "taskName",
        prop: "taskName",
        title: "User Story",

      },
      {
        field: "sprintMatrix",
        prop: "sprintMatrix",
        title: "Efforts in hours",
        cell: (props) => {
          return Math.round(props.sprintMatrix)
        }
      }],
      sprintColumnsF1: [{
        field: "taskName",
        prop: "taskName",
        title: "User Story",
        width: '700px',

      },
      {
        field: "sprintMatrixF1",
        prop: "sprintMatrixF1",
        title: "Efforts in hours",
        cell: (props) => {
          return Math.round(props.sprintMatrixF1)
        }
      }],
      sprintMatrixData: [],
      sprintMatrixDataF1: [],
      showSprintMatrixAlert: false,
      sprintValue: 0,
      modalShow: false,
      showSaveMsg: false,

      clientMsg: "",
      projectMsg: "",
      finalTotal: 0,
      key: "summarytab",
      projectName: "",
      clientName: "",
      data: [],
      allSectionTotal: 0,
      allSectionTotalF1: 0,
      f1TotalPercentReduce: 0,
      collaboratorValue: [],
      chartData: {
        labels: [
          'Section1',
          'Section2',

        ],
        datasets: [{
          data: [0, 0],
          backgroundColor: [
            'turquoise',
            'yellow',

          ]
        }]
      },
      chartDataF1: {
        labels: [
          'Section1',
          'Section2',

        ],
        datasets: [{
          data: [0, 0],
          backgroundColor: [
            'turquoise',
            'yellow',
          ]
        }]
      },
      showMsg: false,
      displaySdlcData: [],
      displaySdlc: [
        {
          id: "1",
          activity: 'Project Management',
          reference: '5',
          // allocated: '5',
          budgeted: '0',
          // f1Allocated:'5',
          fOneRefrence: '5',
          fOneBudgeted: '0',
          justification: ""
        },
        {
          id: "2",
          activity: 'Requirement Gathering',
          reference: '12',
          //allocated: '12',
          budgeted: '0',
          //sf1Allocated:'12',
          fOneRefrence: '12',
          fOneBudgeted: '0'

        },
        {
          id: "3",
          activity: 'Technical Design / Architecture',
          reference: '15',
          //allocated: '15',
          budgeted: '0',
          //f1Allocated:'15',
          fOneRefrence: '15',
          fOneBudgeted: '0',
          justification: ""
        },
        {
          id: "4",
          activity: 'UX Design (Hrs.)',
          reference: '0',
          budgeted: '0',
          //f1Allocated:'5',
          fOneRefrence: '0',
          fOneBudgeted: '0',
          justification: ""
        },
        {
          id: "5",
          activity: 'Code Construction',
          reference: '50',
          //allocated: '50',
          //f1Allocated:'50',
          fOneRefrence: '50',
          fOneBudgeted: '0',
          justification: ""
        },
        {
          id: "6",
          activity: 'QA Testing & Support',
          reference: '11',
          //allocated: '11',
          budgeted: '0',
          //f1Allocated:'11',
          fOneRefrence: '11',
          fOneBudgeted: '0',
          justification: ""
        },
        {
          id: "7",
          activity: 'Performance Testing / VAPT',
          reference: '1',
          //allocated: '1',
          budgeted: '0',
          //f1Allocated:'1',
          fOneRefrence: '1',
          fOneBudgeted: '0',
          justification: ""
        }, {
          id: "8",
          activity: 'Deployment, Release and UAT Support',
          reference: '6',
          //allocated: '6',
          budgeted: '0',
          //f1Allocated:'6',
          fOneRefrence: '6',
          fOneBudgeted: '0',
          justification: ""
        },
        //  {
        //   id: "9",
        //   activity: 'Reporting/BI',
        //   reference: '0',
        //   budgeted: '0',
        //   fOneRefrence: '0',
        //   fOneBudgeted: '0',
        //   justification: ""
        // }, {
        //   id: "10",
        //   activity: 'Data Migration',
        //   reference: '0',
        //   budgeted: '0',
        //   //f1Allocated:'',
        //   fOneRefrence: '0',
        //   fOneBudgeted: '0',
        //   justification: ""
        // }, {
        //   id: "11",
        //   activity: 'Warranty (Hrs)',

        //   reference: '0',
        //   budgeted: '0',
        //   //f1Allocated:'',
        //   fOneRefrence: '0',
        //   fOneBudgeted: '0',
        //   justification: ""
        // },
        {
          id: "12",
          activity: 'TOTAL (A)',
          reference: '0',
          budgeted: '0',
          //f1Allocated:'',
          fOneRefrence: '0',
          fOneBudgeted: '0',
          justification: ""
        }, {
          id: "13",
          activity: 'CONTINGENCY (B)',
          reference: '0',
          budgeted: '0',
          //f1Allocated:'',
          fOneRefrence: '0',
          fOneBudgeted: '0',
          justification: ""
        }, {
          id: '14',
          activity: 'BUDGETED TOTAL COST (A + B)',
          reference: '0',
          budgeted: '0',
          //f1Allocated:'',
          fOneRefrence: '0',
          fOneBudgeted: '0',
          justification: ""
        }, {
          id: "15",
          activity: 'ONE-TIME DISCOUNT (C)',
          reference: '0',
          budgeted: '0',
          //f1Allocated:'',
          fOneRefrence: '0',
          fOneBudgeted: '0',
          justification: ""

        }, {
          id: "16",
          activity: 'BUDGETED TOTAL COST AFTER DISCOUNT (A + B - C)',
          reference: '0',
          budgeted: '0',
          //f1Allocated:'',
          fOneRefrence: '0',
          fOneBudgeted: '0',
          justification: ""
        }
      ],
      estimationMetadata: [
        {
          id: "1",
          key: "Opportunity Name",
          value: ""
        },
        {
          id: "2",
          key: "Project Name",
          value: ""
        },
        {
          id: "3",
          key: "Estimated By",
          value: localStorage.getItem("email")
        },
        {
          id: "4",
          key: "Version",
          value: "1"
        },
        {
          id: "5",
          key: "Technology Platform",
          value: ""
        }
      ],
      questionnaire: [
        {
          id: "1",
          key: "Is this a web application?",
          value: "no"
        },
        {
          id: "2",
          key: "Is this a mobile application?",
          value: "no"
        },
        {
          id: "3",
          key: "Is data migration involved?",
          value: "no"
        },
        {
          id: "4",
          key: "Does this project involve BI?",
          value: "no"
        },
        {
          id: "5",
          key: "Does this project integrate to external systems?",
          value: "no"
        },
        {
          id: "6",
          key: "Is this a microservice application?",
          value: "no"
        },
        {
          id: "7",
          key: "Is this a PWA compatable application?",
          value: "no"
        },
        {
          id: "8",
          key: "Does mobile/web support offline capabilities?",
          value: "no"
        },
        {
          id: "9",
          key: "Is hosting of the solution expected?",
          value: "no"
        },
        {
          id: "10",
          key: "Is LDAP or AD involved for authentication?",
          value: "no"
        },
        {
          id: "11",
          key: "Is the authentication local?",
          value: "no"
        }
      ],
      sizing: [
        {
          id: "1",
          key: "Number of users using the system",
          value: ""
        },
        {
          id: "2",
          key: "Number of concurrent users (%)",
          value: ""
        },
        {
          id: "3",
          key: "Volume of users added YOY (%)",
          value: "20"
        }, {
          id: "4",
          key: "Current data volume (GB)",
          value: "0"
        }, {
          id: "5",
          key: "Page response time (ms)",
          value: "500"
        },

      ],
      message: "",
      msgType: "",
      createdByEmail: "",
      autoSave:false,
      loading:false
    }
  }

  autoSaveOnBlur=(e)=>{
    if(e.target.value && localStorage.getItem('mode')==='edit'){
     this.onSubmit()
    }
  }

  onSelect = (k) => {
    this.setState({ key: k })
    //console.log(this.state.key, "key", k)
  }


  componentWillMount() {

    this.setState({
      data: [
        {
          "sectionId": "1",
          "sectionName": "",
          "sectionDescription": "string",
          "sectionColor": "purple",
          "sectionTotal": 0,
          "f1Efforts": 0,
          "taskModel": [
            {
              "taskId": 1,
              "taskName": "",
              "taskTotal": 0,
              "usingFOne": false,
              "fOneTaskTotal": 0,
              "auditModel": {
                "createdBy": "string",
                "updatedBy": "string",
                "createdDateTime": "2020-10-29T02:05:19.329Z",
                "updatedDateTime": "2020-10-29T02:05:19.329Z"
              }
            },
            {
              "taskId": 2,
              "taskName": "",
              "taskTotal": 0,
              "usingFOne": true,
              "fOneTaskTotal": 0,
              "auditModel": {
                "createdBy": "string",
                "updatedBy": "string",
                "createdDateTime": "2020-10-29T02:05:19.329Z",
                "updatedDateTime": "2020-10-29T02:05:19.329Z"
              }
            }
          ],
          "auditModel": {
            "createdBy": "string",
            "updatedBy": "string",
            "createdDateTime": "2020-10-29T02:05:19.329Z",
            "updatedDateTime": "2020-10-29T02:05:19.329Z"
          }
        }
      ]
    })
  }
  componentDidMount() {
    //console.log("submifirstDidtfinal")

    let mode = localStorage.getItem("mode");
    this.setState({loading:true})

    //SDLC
   
    if (mode === "edit") {
      let rowData = localStorage.getItem("rowData");
      let projectId = JSON.parse(rowData).projectId;

      let encodeProjectId = btoa(unescape(encodeURIComponent(projectId)))
      let hostName = window.location.href.replace(this.props.history.location.pathname, '')
      let uniqueShareableLink = hostName + "/viewEstimate/"+ encodeProjectId
      this.setState({uniqueShareableLink : uniqueShareableLink})

      let newEstimationMetadata = JSON.parse(rowData) && JSON.parse(rowData).estimationMetadata.map(el => {
        if (el.id == 3) {
          el.value = JSON.parse(rowData).auditModel.createdBy;
        }
        return el;
      })



      this.setState({ createdByEmail: JSON.parse(rowData) && JSON.parse(rowData).auditModel.createdBy })

      this.setState({ estimationMetadata: newEstimationMetadata })
      this.setState({ questionnaire: JSON.parse(rowData) && JSON.parse(rowData).questionnaire })
      this.setState({ sizing: JSON.parse(rowData) && JSON.parse(rowData).sizing })
      this.setState({ collaboratorValue: JSON.parse(rowData) && JSON.parse(rowData).collabarators ? JSON.parse(rowData).collabarators : [] })
      this.setState({

        displaySdlc: JSON.parse(rowData).effortsSdlc,


      })
      get(EDIT_ESTIMATE_BY_PROJECTID + projectId).then(res => {
  
        let rowDetails = res.data;
        //console.log('rowDetails',rowDetails)

        let autoGenerateCol = [];
        rowDetails.sprintMatrixes && rowDetails.sprintMatrixes[0].sprints.map((c, i) => {
          autoGenerateCol.push({
            field: c.id,
            prop: c.id,
            title: (c.id).toUpperCase(),
            align: "right",
            cell: (props) => {
              return <input type="checkbox" className="form-control checkbox" onChange={(e) => this.onChangeSelectedSprint(e, props, true)} checked={props.sprints[i].value} id={props.sprints[i].id} />
            }
          })
        })
        let autoGenerateColF1 = [];
        rowDetails.sprintMatrixesF1 && rowDetails.sprintMatrixesF1[0].sprints.map((c, i) => {
          autoGenerateColF1.push({
            field: c.id,
            prop: c.id,
            title: (c.id).toUpperCase(),
            align: "right",
            cell: (props) => {
              return <input type="checkbox" className="form-control checkbox" onChange={(e) => this.onChangeSelectedSprintF1(e, props, true)} checked={props.sprints[i].value} id={props.sprints[i].id} />
            }
          })
        })
        
        this.setState({
          //  dataForSprintMatrixCalculation: res.data,
          sprintMatrixState: rowDetails.sprintMartrixCapacity,
          sprintValue: rowDetails.numberOfSprints,
          sprintColumns: [...this.state.sprintColumns, ...autoGenerateCol],
          sprintColumnsF1: [...this.state.sprintColumnsF1, ...autoGenerateColF1],
          sprintMatrixData: rowDetails.sprintMatrixes ? rowDetails.sprintMatrixes : [],
          sprintMatrixDataF1: rowDetails.sprintMatrixesF1 ? rowDetails.sprintMatrixesF1 : [],
          clientName: rowDetails.clientName,
          projectName: rowDetails.projectName,
          data: rowDetails.sectionModel,
          allSectionTotal: rowDetails.projectTotal,
          allSectionTotalF1: rowDetails.f1Efforts,
          f1TotalPercentReduce: ((parseInt(rowDetails.projectTotal) - parseInt(rowDetails.f1Efforts)) / parseInt(rowDetails.projectTotal)) * 100,
          //displaySdlc: JSON.parse(rowData).effortsSdlc,
          //  sprintMatrixData:filterTask,
          // dataForSprintMatrixCalculation:res.data
        })

        //console.log("responserowDetails", rowDetails)
        //console.log("kkkkllll",((parseInt(rowDetails.projectTotal)-parseInt(rowDetails.f1Efforts))/parseInt(rowDetails.projectTotal))*100)
        this.getAllTotal()
        this.getChartData()
        this.setState({loading:false})
      })
     


    } else {
      // this.setState({displaySdlc:this.state.displaySdlc});
      // this.setState({ data: taskViewer[0].sectionModel })
      // this.getAllTotal()
      // this.getChartData()
      this.setState({loading:false})
     
    }



  }
  // onSubmit=()=>{
  //   this.setState({modalShow:!this.state.modalShow})
  // }
  onSubmit = () => {
    //console.log("submitfinal")
    let mode = localStorage.getItem('mode');
    let clientName = this.state.clientName;
    let projectName = this.state.projectName;
    let updatedTotal = this.state.allSectionTotal;
    let sectionModel = this.state.data;
    let techAssumption = JSON.parse(localStorage.getItem('displaytechAssumption'));
    let genAssumption = JSON.parse(localStorage.getItem('displayGenAssumption'));
    let outOfScope = JSON.parse(localStorage.getItem('displayOutOfScope'));

    let tech = []
    let gen = []
    let oos = []
    techAssumption && techAssumption.map(el => {
      tech.push({ description: el.description, id: el.id.toString() })
    })
    genAssumption && genAssumption.map(el => {
      gen.push({ description: el.description, id: el.id.toString() })
    })

  outOfScope && outOfScope.map(el=>{
    oos.push({description: el.description, id: el.id.toString()})
  })

    //SDLC
    // let sdlcGetLocalStorage = JSON.parse(localStorage.getItem('sdlc'));
    let sdlc = []
    this.state.displaySdlc && this.state.displaySdlc.map(el => {
      sdlc.push({
        id: el.id.toString(),
        activity: el.activity,
        reference: el.reference.toString(),
        fOneRefrence: el.fOneRefrence.toString(),
        budgeted: el.budgeted ? el.budgeted.toString() : "0",
        fOneBudgeted: el.fOneBudgeted.toString(),
        justification: el.justification
      })

    })

    let email = localStorage.getItem('email')
    let a = this.state.estimationMetadata && this.state.estimationMetadata.filter(el => el.value == "")
    let b = this.state.questionnaire && this.state.questionnaire.filter(el => el.value == "")
    let c = this.state.sizing && this.state.sizing.filter(el => el.value == "")
    //console.log("A",a)

    let selectedCollaborator = this.state.collaboratorValue && this.state.collaboratorValue.map(el => {
      el.id = el.id.toString()
      return el
    })
    if (this.state.clientName && this.state.projectName && a.length == 0 && b.length == 0 && c.length == 0) {
      if (mode === 'edit') {
        let rowData = JSON.parse(localStorage.getItem("rowData"));
        let schema = {
          "id": rowData.id,
          "projectId": rowData.projectId,
          "projectName": projectName,
          "clientName": clientName,
          "projectDescription": "string",
          "projectColor": "string",
          "projectTotal": updatedTotal,
          "sectionModel": sectionModel,
          "auditModel": {
            "createdBy": rowData.auditModel.createdBy,
            "updatedBy": email,
            "createdDateTime": rowData.auditModel.createdDateTime,
            "updatedDateTime": new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString()
          },
          "assumptions": {
            "technicalAssumption": tech,
            "generalAssumption": gen,
            "outOfScope":oos
          },
          "effortsSdlc": sdlc,
          "estimationMetadata": this.state.estimationMetadata,
          "questionnaire": this.state.questionnaire,
          "sizing": this.state.sizing,
          "status": rowData.status,
          "collabarators": selectedCollaborator,
          "responseMessage": "string",
          "sprintMatrixes": this.state.sprintMatrixData.length > 0 ? this.state.sprintMatrixData : null,
          "sprintMatrixesF1": this.state.sprintMatrixDataF1.length > 0 ? this.state.sprintMatrixDataF1 : null,
          "sprintMartrixCapacity": parseInt(this.state.sprintMatrixState),
          "numberOfSprints": parseFloat(this.state.sprintValue)
        }
        post(EDIT_ESTIMATE, schema).then(res => {
          
          //this.props.history.push("/home")
          this.setState({ showSaveMsg: true, message: "Updated successfully!", msgType: "success" })
          this.setState({ modalShow: false })
        })
      } else {
        let getAllList = 0
        get(GET_MAX_PROJECT_ID).then(res => {
          getAllList = res.data;
          //console.log("GET_MAX_PROJECT_ID",res)
          let schema = {
            "id": "",
            "projectId": getAllList && getAllList > 0 ? (getAllList + 1).toString() : "1000",
            "projectName": projectName,
            "clientName": clientName,
            "projectDescription": "Inhouse project 33",
            "projectColor": "Green",
            "projectTotal": updatedTotal,
            "sectionModel": sectionModel,
            "auditModel": {
              "createdBy": email,
              "updatedBy": email,
              "createdDateTime": new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString(),
              "updatedDateTime": new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString()
            },
            "assumptions": {
              "technicalAssumption": tech,
              "generalAssumption": gen,
              "outOfScope":oos
            },
            "effortsSdlc": sdlc,
            "estimationMetadata": this.state.estimationMetadata,
            "questionnaire": this.state.questionnaire,
            "sizing": this.state.sizing,
            "status": "open",
            "collabarators": selectedCollaborator,
            "responseMessage": "string",
            "isDeleted": false,
            "sprintMatrixes": this.state.sprintMatrixData.length > 0 ? this.state.sprintMatrixData : null,
            "sprintMatrixesF1": this.state.sprintMatrixDataF1.length > 0 ? this.state.sprintMatrixDataF1 : null,
            "sprintMartrixCapacity": parseInt(this.state.sprintMatrixState),
            "numberOfSprints": parseFloat(this.state.sprintValue)
          }

          post(ADD_ESTIMATE, schema).then(res => {
            // console.log("add estimate",res.data)
            if (res.data.responseMessage == "Record already existed") {
              this.setState({ showSaveMsg: true, message: res.data.responseMessage + " Please change the project name.", msgType: "error" })
              this.setState({ modalShow: false })
            } else {
              localStorage.setItem("rowData", JSON.stringify(res.data));
              localStorage.setItem("mode", "edit");
              //this.props.history.push("/home")
              this.setState({ showSaveMsg: true, message: "Saved successfully!", msgType: "success" })
              this.setState({ modalShow: false })
            }
          })
          // }
        })
      }
    } else {
      this.setState({ showSaveMsg: true, message: "Client Name, Project Name,Estimation Metadata, Questionnaire and Sizing are required fields!", msgType: "error" })

      if (!this.state.clientName) {
        this.setState({ clientMsg: "Client name is required" })
      }
      if (!this.state.projectName) {
        this.setState({ projectMsg: "Project name is required" })
      }
    }
  }


  fieldChange = (e, id, type) => {
    // console.log("fieldChange", e.target.value, type)
    
    let justificationValue = e.target.value
    e.target.value=e.target.value?e.target.value.slice(0,3):0;
    let updatedTotal = localStorage.getItem("updatedTotal");
    let updatedObj = this.state.displaySdlc && this.state.displaySdlc.map(el => {
      if (el.id === id) {
        if (type === "reference") {
          el.reference = parseInt(e.target.value);
          el.budgeted = (this.state.allSectionTotal * (parseInt(parseInt(e.target.value) * 2) / 100)).toString()
        } else if (type === "budgeted") {
          el.budgeted = e.target.value;
          el.fOneBudgeted = e.target.value;
        } else if (type === "justification") {
          el.justification = justificationValue;
         
        } else {
          el.fOneRefrence = parseInt(e.target.value);
          el.fOneBudgeted = (this.state.allSectionTotalF1 * (parseInt(parseInt(e.target.value) * 2) / 100)).toString();

        }
      }
      return el
    })

    
    let updatedObj1 = updatedObj && updatedObj.map(el => {
      let sum = 0;
      let sumF1 = 0;
      for (var i = 0; i < updatedObj.length - 5; i++) {
console.log("i bu",i,updatedObj[i].budgeted)
        sum += parseFloat(updatedObj[i].budgeted)
        sumF1 += parseFloat(updatedObj[i].fOneBudgeted)

      }
      let bud12 = this.state.displaySdlc[8].budgeted;
      let bud13 = this.state.displaySdlc[9].budgeted;

      console.log("bud12",bud12,"bud13",bud13)

      let bud12F1 = this.state.displaySdlc[8].fOneBudgeted;
      let bud13F1 = this.state.displaySdlc[9].fOneBudgeted;

      let oneTimeDisc = this.state.displaySdlc[10].budgeted;
      let oneTimeDiscF1 = this.state.displaySdlc[10].fOneBudgeted;

      console.log("oneTimeDisc",oneTimeDisc,"oneTimeDiscF1",oneTimeDiscF1)

      let costafterDisc = parseInt(oneTimeDisc) - parseInt(this.state.displaySdlc[11].budgeted);
      let costafterDiscF1 = parseInt(oneTimeDiscF1) - parseInt(this.state.displaySdlc[11].fOneBudgeted);
      if (el.id == "4") {
        el.budgeted = el.budgeted;
        el.fOneBudgeted = el.fOneBudgeted
      } else if (el.id == "12") {
        el.budgeted = sum;
        el.fOneBudgeted = sumF1;
        console.log("sum",sum)
      } else if (el.id == "13") {
     
        el.budgeted = (this.state.displaySdlc[8].budgeted * (parseFloat(el.reference) / 100)).toString() || 0;
        el.fOneBudgeted = (this.state.displaySdlc[8].fOneBudgeted * (parseFloat(el.fOneRefrence)/100)).toString() || 0;
       
      } else if (el.id == "14") {
        el.budgeted = parseFloat(bud12) + parseFloat(bud13);
        el.fOneBudgeted = parseFloat(bud12F1) + parseFloat(bud13F1);

      } else if (el.id == "15") {
        el.budgeted = (oneTimeDisc * parseFloat(el.reference) / 100).toString();
        el.fOneBudgeted = (oneTimeDiscF1 * parseFloat(el.fOneRefrence) / 100).toString();
      } else if (el.id == "16") {
       // console.log("last",el,costafterDisc)
    //    el.budgeted = costafterDisc;
    el.budgeted =this.state.displaySdlc[10].budgeted-this.state.displaySdlc[11].budgeted
        el.fOneBudgeted = this.state.displaySdlc[10].fOneBudgeted-this.state.displaySdlc[11].fOneBudgeted
      } else {
        el.budgeted = (this.state.allSectionTotal * (parseFloat(el.reference * 2) / 100)).toString() || 0;
        el.fOneBudgeted = (this.state.allSectionTotalF1 * (parseFloat(el.fOneRefrence * 2) / 100)).toString() || 0
      }

      return el
    })


    this.setState({ displaySdlc: updatedObj1 })
  }

  onBlurName = (e) => {
    this.autoSaveOnBlur(e)
  }


  onProjectandClientChange = (e) => {
    //console.log("projectName", e.target.id, e.target.value)
    
    if (e.target.id === 'projectName') {
      this.setState({ projectMsg: "" })
      let newEstimationMetadata = this.state.estimationMetadata.map(el => {
        if (el.id == 2) {
          el.value = e.target.value
        }
        return el;
      })
      this.setState({ estimationMetadata: newEstimationMetadata })
    }
    if (e.target.id === 'clientName') {
      this.setState({ clientMsg: "" })
      let newEstimationMetadata = this.state.estimationMetadata.map(el => {
        if (el.id == 1) {
          el.value = e.target.value
        }
        return el
      })
      this.setState({ estimationMetadata: newEstimationMetadata })

    }
    this.setState({ [e.target.id]: e.target.value })
    this.setState({ showMsg: false })

  }



  //DEV
  getChartData = () => {

    let labels = [];
    let datasetsData = [];
    let datasetsDataF1 = [];
    let color = [];
    let generateDataSet = this.state.data;
    generateDataSet && generateDataSet.map(item => {
      color.push(item.sectionColor)
      datasetsData.push(item.sectionTotal)
      datasetsDataF1.push(item.f1Efforts)
      labels.push(item.sectionName)
    })
    let totalData = {
      labels: labels,
      datasets: [{
        data: datasetsData,
        backgroundColor: color,
        hoverBackgroundColor: color
      }]
    }

    let totalDataF1 = {
      labels: labels,
      datasets: [{
        data: datasetsDataF1,
        backgroundColor: color,
        hoverBackgroundColor: color
      }]
    }
    this.setState({ chartData: totalData, chartDataF1: totalDataF1 })
  }
  addTask = (e, id) => {
    //console.log(this.state.data, id)
    let co = 0;
    let characters = this.state.data.filter(el => el.sectionId === id)[0].taskModel;
    //console.log("dd",characters)
    let max = characters.reduce(function (prev, current) {
      if (+current.taskId > +prev.taskId) {
        return current;
      } else {
        return prev;
      }
    });
    co = max.taskId + 1;
    //console.log(co)
    const obj = {
      "taskId": co,
      "taskName": ""
    }
    const count = this.state.data.map(el => {
      if (el.sectionId === id) {
        let elTask = el.taskModel.push(obj)
        let d = { ...el }
        return d
      } else {
        return el
      }
    });
    this.setState({ data: count })
    this.getChartData()
  }


  addSection = (e, id) => {
    let getLength = this.state.data.length + 1;



    let characters = this.state.data;
    // let totalCount = 0;
    var totalCount = 0;
    let max = characters.reduce(function (prev, current) {
      if (+current.sectionId > +prev.sectionId) {
        return current;
      } else {
        return prev;
      }
    });
    totalCount = parseInt(max.sectionId) + 1;
    //console.log("totalCount",this.state.data,totalCount)
    let colors = ['#831C78', '#AF30A2', '#AC55A3', '#E67BDB', "#5B2C6F"];
    let selectedColor = colors[Math.floor(Math.random() * colors.length)]
    //console.log("dd", selectedColor, id)
    const obj = {
      "sectionId": totalCount.toString(),
      "sectionName": "",
      "sectionColor": selectedColor,
      "sectionTotal": 0,
      "f1Efforts": 0,
      "taskModel": [
        {
          "taskId": 1,

          "taskName": "",
          "taskTotal": 0,
        }
      ]
    }
    const a = [...this.state.data, obj]
    this.setState({ data: a })
    this.getChartData()
  }

  changeColor = (e, id, color) => {
    let newColor = this.state.data.map(el => {
      if (el.sectionId === id) {
        el.sectionColor = color;
      }
      return el
    })
    //console.log("color", color, newColor)
    this.setState({ data: newColor })
    this.getChartData()
  }

  hideShowPallete = (e, id) => {
    //console.log(id)
  }

  sectionOnchange = (e, id, type) => {
    //console.log(e.target.id, typeof (e.target.value))
    
    this.setState({ [e.target.id]: e.target.value })
    let allSecTotalF1 = 0;
    let updatedObj = this.state.data.map(el => {
      if (el.sectionId === id) {

        el.sectionName = e.target.value;

      }
      return el
    })
    this.setState({ data: updatedObj })
    this.getChartData()

  }

  taskOnchange = (e, sectionId, taskId, name, check) => {
    if(name === 'total' || name === 'fOneTaskTotal'){
      e.target.value=e.target.value==""?0:parseInt(e.target.value.slice(0, 2));
    }
    this.setState({ [e.target.id]: e.target.value })
    
    let taskTotal = 0;
    let fOneTaskTotal = 0;
    let allSecTotal = 0;
    let allSecTotalF1 = 0;
    //console.log("fOneTaskTotal", e.target, !this.state["usingFOne" + taskId])
    const getSection = this.state.data.map(el => {
      if (el.sectionId === sectionId) {
        el.taskModel && el.taskModel.map(item => {
          if (item.taskId === taskId) {

            if (name === "fOneTaskTotal") {
              // el.fOneTaskTotal = parseInt(e.target.value)
              Object.assign(item, { fOneTaskTotal: parseInt(e.target.value) })
            } else if (name === "usingFOne") {
              this.setState({ [e.target.id]: !this.state["usingFOne" + taskId] })
              //  el.usingFOne = !this.state["usingFOne" + taskId]
              if (check) {

                Object.assign(item, { usingFOne: !this.state["usingFOne" + taskId], fOneTaskTotal: parseInt(item.fOneTaskTotal) })
                Object.assign(el, {
                  f1Efforts: el.taskModel && el.taskModel.map(ite => {
                    // taskTotal += parseInt(ite.taskTotal) || 0
                    fOneTaskTotal += parseInt(ite.fOneTaskTotal) || 0
                  })
                });
              } else {
                Object.assign(item, { usingFOne: !this.state["usingFOne" + taskId], fOneTaskTotal: parseInt(item.taskTotal) })


              }

            } else if (name === "name") {//for epic name change
              e.target.style.height=e.target.scrollHeight+'px';
              Object.assign(item, { taskName: e.target.value })
            } else if(name === "total" && e.target.value.length > 2){
              Object.assign(item, { total: parseInt(e.target.value) })
            }
            else {
              if (check) {
                if (name === "total") {
                  e.target.value=e.target.value==""?0:e.target.value;
                  if (item.fOneTaskTotal > e.target.value) {
                    item.fOneTaskTotal = parseInt(e.target.value)
                  }
                }
                Object.assign(item, { taskTotal: parseInt(e.target.value) })
              } else {
                Object.assign(item, { taskTotal: parseInt(e.target.value), fOneTaskTotal: parseInt(e.target.value) })
              }
            }
            return item;
          }
        })
      }

      return el
    })

    let newGetSection = getSection.map(el => {
      if (el.sectionId === sectionId) {
        el.taskModel && el.taskModel.map(item => {
          taskTotal += parseInt(item.taskTotal) || 0
          fOneTaskTotal += parseInt(item.fOneTaskTotal) || 0
        })
        el.sectionTotal = taskTotal || 0;
        el.f1Efforts = fOneTaskTotal || 0;
      }
      allSecTotal += parseInt(el.sectionTotal || 0)
      allSecTotalF1 += parseInt(el.f1Efforts || 0)
      return el;
    })

    this.setState({ allSectionTotal: allSecTotal })
    this.setState({ allSectionTotalF1: allSecTotalF1 })
    this.setState({
      f1TotalPercentReduce: ((parseInt(allSecTotal) - parseInt(allSecTotalF1)) / parseInt(allSecTotal)) * 100
    })
    this.setState({ data: newGetSection, })
    this.getChartData()

    let updatedObj = this.state.displaySdlc && this.state.displaySdlc.map(el => {
      let sum = 0;
      let sumF1 = 0;
      for (var i = 0; i < this.state.displaySdlc.length - 5; i++) {

        sum += parseInt(this.state.displaySdlc[i].budgeted)
        sumF1 += parseInt(this.state.displaySdlc[i].fOneBudgeted)

      }
      //console.log("sumsum", sum, sumF1)
      let bud12 = this.state.displaySdlc[8].budgeted;
      let bud13 = this.state.displaySdlc[9].budgeted;

      let bud12F1 = this.state.displaySdlc[8].fOneBudgeted;
      let bud13F1 = this.state.displaySdlc[9].fOneBudgeted;

      let oneTimeDisc = this.state.displaySdlc[10].budgeted;
      let oneTimeDiscF1 = this.state.displaySdlc[10].fOneBudgeted;

      let costafterDisc = parseInt(oneTimeDisc) - parseInt(this.state.displaySdlc[11].budgeted);
      let costafterDiscF1 = parseInt(oneTimeDiscF1) - parseInt(this.state.displaySdlc[11].fOneBudgeted);

      if (el.id == "12") {
        el.budgeted = sum;
        el.fOneBudgeted = sumF1;
      } else if (el.id == "13") {
        el.budgeted = Math.round(allSecTotal * (parseInt(el.reference * 2) / 100)).toString() || 0;
        el.fOneBudgeted = Math.round(allSecTotalF1 * (parseInt(el.fOneRefrence * 2) / 100)).toString() || 0;
      } else if (el.id == "14") {
        el.budgeted = Math.round(parseInt(bud12) + parseInt(bud13)).toString();
        el.fOneBudgeted = Math.round(parseInt(bud12F1) + parseInt(bud13F1)).toString();

      } else if (el.id == "15") {
        el.budgeted = Math.round(oneTimeDisc * parseInt(el.reference) / 100).toString();
        el.fOneBudgeted = Math.round(oneTimeDiscF1 * parseInt(el.fOneRefrence) / 100).toString();
      } else if (el.id == "16") {
        el.budgeted = costafterDisc;
        el.fOneBudgeted = costafterDiscF1
      } else if (el.id == "4") {
        el.budgeted = el.budgeted;
        el.fOneBudgeted = el.fOneBudgeted
      } else {
        el.budgeted = Math.round(allSecTotal * (parseInt(el.reference * 2) / 100)).toString() || 0;
        el.fOneBudgeted = Math.round(allSecTotalF1 * (parseInt(el.fOneRefrence * 2) / 100)).toString() || 0
      }

      return el
    })

    this.setState({ displaySdlc: updatedObj })

  }

  getAllTotal = () => {
    let allSecTotal = 0;
    let allSecTotalF1 = 0;
    let taskTotal = 0;
    this.state.data.map(el => {
      allSecTotal += parseInt(el.sectionTotal || 0)
      allSecTotalF1 += parseInt(el.f1Efforts || 0)
      return el;
    })
    this.setState({ allSectionTotal: allSecTotal, allSectionTotalF1: allSecTotalF1 })
    this.setState({
      f1TotalPercentReduce: ((parseInt(allSecTotal) - parseInt(allSecTotalF1)) / parseInt(allSecTotal)) * 100
    })
  }
  onDeleteSection = (e, id) => {
    if (this.state.data.length > 1) {
      const a = this.state.data.filter(el => el.sectionId !== id)
      let allSecTotal = 0;
      let allSecTotalF1 = 0;
      a.map(el => {
        let taskTotal1 = 0;
        let fOneTaskTotal = 0;
        el.taskModel && el.taskModel.map(item => {
          taskTotal1 += parseInt(item.taskTotal) || 0
          fOneTaskTotal += parseInt(item.fOneTaskTotal) || 0
        })
        el.sectionTotal = taskTotal1 || 0;
        el.f1Efforts = fOneTaskTotal || 0;

        allSecTotal += parseInt(taskTotal1 || 0)
        allSecTotalF1 += parseInt(fOneTaskTotal || 0)
        return el;
      })
      ///////////////////////////////////////////////////

      let updatedObj = this.state.displaySdlc && this.state.displaySdlc.map(el => {
        let sum = 0;
        let sumF1 = 0;
        for (var i = 0; i < this.state.displaySdlc.length - 5; i++) {

          sum += parseInt(this.state.displaySdlc[i].budgeted)
          sumF1 += parseInt(this.state.displaySdlc[i].fOneBudgeted)

        }
        //console.log("sumsum", sum, sumF1)
        let bud12 = this.state.displaySdlc[8].budgeted;
        let bud13 = this.state.displaySdlc[9].budgeted;

        let bud12F1 = this.state.displaySdlc[8].fOneBudgeted;
        let bud13F1 = this.state.displaySdlc[9].fOneBudgeted;

        let oneTimeDisc = this.state.displaySdlc[10].budgeted;
        let oneTimeDiscF1 = this.state.displaySdlc[10].fOneBudgeted;

        let costafterDisc = parseInt(oneTimeDisc) - parseInt(this.state.displaySdlc[11].budgeted);
        let costafterDiscF1 = parseInt(oneTimeDiscF1) - parseInt(this.state.displaySdlc[11].fOneBudgeted);

        if (el.id == "12") {
          el.budgeted = sum;
          el.fOneBudgeted = sumF1;
        } else if (el.id == "13") {
          el.budgeted = Math.round(allSecTotal * (parseInt(el.reference * 2) / 100)).toString() || 0;
          el.fOneBudgeted = Math.round(allSecTotalF1 * (parseInt(el.fOneRefrence * 2) / 100)).toString() || 0;
        } else if (el.id == "14") {
          el.budgeted = Math.round(parseInt(bud12) + parseInt(bud13)).toString();
          el.fOneBudgeted = Math.round(parseInt(bud12F1) + parseInt(bud13F1)).toString();

        } else if (el.id == "15") {
          el.budgeted = Math.round(oneTimeDisc * parseInt(el.reference) / 100).toString();
          el.fOneBudgeted = Math.round(oneTimeDiscF1 * parseInt(el.fOneRefrence) / 100).toString();
        } else if (el.id == "16") {
          el.budgeted = costafterDisc;
          el.fOneBudgeted = costafterDiscF1
        } else if (el.id == "4") {
          el.budgeted = el.budgeted;
          el.fOneBudgeted = el.fOneBudgeted
        } else {
          el.budgeted = Math.round(allSecTotal * (parseInt(el.reference * 2) / 100)).toString() || 0;
          el.fOneBudgeted = Math.round(allSecTotalF1 * (parseInt(el.fOneRefrence * 2) / 100)).toString() || 0
        }

        return el
      })

      this.setState({ displaySdlc: updatedObj })



      this.getChartData();
      this.getAllTotal();
      ///////////////////////////////////////////////




      this.setState({
        data: a, allSectionTotal: allSecTotal,
        allSectionTotalF1: allSecTotalF1
      })

      this.setState({
        f1TotalPercentReduce: ((parseInt(allSecTotal) - parseInt(allSecTotalF1)) / parseInt(allSecTotal)) * 100
      })
      let labels = [];
      let datasetsData = [];
      let datasetsDataF1 = [];
      let color = [];
      let generateDataSet = a;
      generateDataSet && generateDataSet.map(item => {
        color.push(item.sectionColor)
        datasetsData.push(item.sectionTotal)
        datasetsDataF1.push(item.f1Efforts)
        labels.push(item.sectionName)
      })
      let totalData = {
        labels: labels,
        datasets: [{
          data: datasetsData,
          backgroundColor: color,
          hoverBackgroundColor: color
        }]
      }

      let totalDataF1 = {
        labels: labels,
        datasets: [{
          data: datasetsDataF1,
          backgroundColor: color,
          hoverBackgroundColor: color
        }]
      }
      this.setState({ chartData: totalData, chartDataF1: totalDataF1 })

    }

    // this.getAllTotal()
  }

  onDeleteTask = (e, id, taskId, taskTotal, taskTotalF1) => {
    //console.log("clicked", e, id, taskId, this.state.data)

    let allSecTotal = 0;
    let allSecTotalF1 = 0;
    let taskTotal1 = 0;
    let fOneTaskTotal = 0;


    let filteredData = this.state.data.map(el => {
      if (el.sectionId == id) {
        let a = []

        if (el.taskModel && el.taskModel.length > 1) {
          el.sectionTotal = parseInt(el.sectionTotal - parseInt(taskTotal))
          el.f1Efforts = parseInt(el.f1Efforts - parseInt(taskTotalF1))
          el.taskModel && el.taskModel.map(tsk => {
            if (tsk.taskId !== taskId) {
              a.push(tsk)
            }

            el.taskModel = a

          })


          //console.log("sdd", a)
        }
      }
      return el;
    }
    )


    let newGetSection = filteredData.map(el => {
      if (el.sectionId === id) {
        el.taskModel && el.taskModel.map(item => {
          taskTotal1 += parseInt(item.taskTotal) || 0
          fOneTaskTotal += parseInt(item.fOneTaskTotal) || 0
        })
        el.sectionTotal = taskTotal1 || 0;
        el.f1Efforts = fOneTaskTotal || 0;
      }
      allSecTotal += parseInt(el.sectionTotal || 0)
      allSecTotalF1 += parseInt(el.f1Efforts || 0)
      return el;
    })

    this.setState({ allSectionTotal: allSecTotal })
    this.setState({ allSectionTotalF1: allSecTotalF1 })
    this.setState({
      f1TotalPercentReduce: ((parseInt(allSecTotal) - parseInt(allSecTotalF1)) / parseInt(allSecTotal)) * 100
    })
    this.setState({ data: newGetSection })
    this.getChartData()

    let updatedObj = this.state.displaySdlc && this.state.displaySdlc.map(el => {
      let sum = 0;
      let sumF1 = 0;
      for (var i = 0; i < this.state.displaySdlc.length - 5; i++) {

        sum += parseInt(this.state.displaySdlc[i].budgeted)
        sumF1 += parseInt(this.state.displaySdlc[i].fOneBudgeted)

      }
      //console.log("sumsum", sum, sumF1)
      let bud12 = this.state.displaySdlc[8].budgeted;
      let bud13 = this.state.displaySdlc[9].budgeted;

      let bud12F1 = this.state.displaySdlc[8].fOneBudgeted;
      let bud13F1 = this.state.displaySdlc[9].fOneBudgeted;

      let oneTimeDisc = this.state.displaySdlc[10].budgeted;
      let oneTimeDiscF1 = this.state.displaySdlc[10].fOneBudgeted;

      let costafterDisc = parseInt(oneTimeDisc) - parseInt(this.state.displaySdlc[11].budgeted);
      let costafterDiscF1 = parseInt(oneTimeDiscF1) - parseInt(this.state.displaySdlc[11].fOneBudgeted);

      if (el.id == "12") {
        el.budgeted = sum;
        el.fOneBudgeted = sumF1;
      } else if (el.id == "13") {
        el.budgeted = Math.round(allSecTotal * (parseInt(el.reference * 2) / 100)).toString() || 0;
        el.fOneBudgeted = Math.round(allSecTotalF1 * (parseInt(el.fOneRefrence * 2) / 100)).toString() || 0;
      } else if (el.id == "14") {
        el.budgeted = Math.round(parseInt(bud12) + parseInt(bud13)).toString();
        el.fOneBudgeted = Math.round(parseInt(bud12F1) + parseInt(bud13F1)).toString();

      } else if (el.id == "15") {
        el.budgeted = Math.round(oneTimeDisc * parseInt(el.reference) / 100).toString();
        el.fOneBudgeted = Math.round(oneTimeDiscF1 * parseInt(el.fOneRefrence) / 100).toString();
      } else if (el.id == "16") {
        el.budgeted = costafterDisc;
        el.fOneBudgeted = costafterDiscF1
      } else if (el.id == "4") {
        el.budgeted = el.budgeted;
        el.fOneBudgeted = el.fOneBudgeted
      } else {
        el.budgeted = Math.round(allSecTotal * (parseInt(el.reference * 2) / 100)).toString() || 0;
        el.fOneBudgeted = Math.round(allSecTotalF1 * (parseInt(el.fOneRefrence * 2) / 100)).toString() || 0
      }

      return el
    })

    this.setState({ displaySdlc: updatedObj })
    this.getChartData();
    this.getAllTotal();
    //console.log("filterDelete", filteredData)

  }

  onDrop = (dropResult) => {
    //console.log(dropResult, this.state.data)
    this.setState({ data: applyDrag(this.state.data, dropResult) })
    this.getChartData()
  }
  onDropTask = (dropResult, id) => {
    //console.log("dropResult", this.state.data)

    let updatedObj = this.state.data.map(el => {
      if (el.sectionId === id) {
        el.taskModel = applyDrag(el.taskModel, dropResult);
      }
      //console.log("demo", el.taskModel)
      return el
    })
    this.setState({ data: updatedObj })
    this.getChartData()
  }

  onBack = (e) => {
    e.preventDefault();
    this.props.history.push("/home")
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ showSaveMsg: false })
  };

  handleCloseDisclaimer=(ev,reason)=>{
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openDisclaimer: false })
  }
  //Summary Tab start
  rowUpdate = (newData, oldData) => {
    //console.log(newData, oldData)
    const index = this.state.estimationMetadata.indexOf(oldData);
    this.setState({ estimationMetadata: this.state.estimationMetadata.map(item => item === oldData ? item = newData : item) });
  }

  rowUpdate1 = (newData, oldData) => {
    //console.log(newData, oldData)
    const index = this.state.questionnaire.indexOf(oldData);
    this.setState({ questionnaire: this.state.questionnaire.map(item => item === oldData ? item = newData : item) });
  }

  rowUpdate2 = (newData, oldData) => {
    //console.log(newData, oldData)
    const index = this.state.sizing.indexOf(oldData);
    this.setState({ sizing: this.state.sizing.map(item => item === oldData ? item = newData : item) });
  }

  summaryFieldChange = (e, id, type) => {
    
    if (type === "value") {
      let updatedObj = this.state.estimationMetadata && this.state.estimationMetadata.map(el => {
        if (el.id === id) {
          el.value = e.target.value

        }
        return el
      })
      this.setState({ estimationMetadata: updatedObj })
      //console.log("estimationMetadata",this.state.estimationMetadata)
    }

    //questionnaire

    if (type === "questionnaire") {
      let updatedObj = this.state.questionnaire && this.state.questionnaire.map(el => {
        if (el.id === id) {
          el.value = e.target.checked ? "yes" : "no"

        }
        return el
      })
      // console.log(updatedObj)
      // console.log("hjghfhg",e.target.checked)
      this.setState({ questionnaire: updatedObj })
    }

    //columnsSizing
    if (type === "sizing") {
      let updatedObj = this.state.sizing && this.state.sizing.map(el => {
        if (el.id === id) {
          el.value = e.target.value

        }
        return el
      })
      this.setState({ sizing: updatedObj })
    }
  }

  assumptionFieldChange = (e, id, type) => {
    //console.log("assumptionFieldChange",e)
  }
  onChangeCollaborator = (e, value) => {
    this.setState({ collaboratorValue: value })
    
  }
  //Summary tab end
  storyPointChange = (e) => {
    this.setState({ storyPoint: e.target.value })
    console.log("stoypointChange", e.target.value)
    
  }
  onChangeMatrix = (e) => {
    e.target.value = parseFloat(e.target.value) ? parseFloat(e.target.value.slice(0, 8)) : 0;
this.setState({showCapacityAlert:false})
    let getSdlCTotal = this.state.displaySdlc.filter(itemD => itemD.activity === "BUDGETED TOTAL COST AFTER DISCOUNT (A + B - C)")[0];
    let getSDLCBudget = getSdlCTotal.budgeted;
    let getSDLCBudgetF1 = getSdlCTotal.fOneBudgeted;
    let spTaskTotal = 0;
    let spTaskTotalF1 = 0;
    this.state.data.map(item => {
      item.taskModel.map(el => {
        let sprintMatrix = (el.taskTotal) * this.state.storyPoint * (parseInt(getSDLCBudget) / this.state.allSectionTotal);
        let sprintMatrixF1 = el.fOneTaskTotal * this.state.storyPoint * (parseInt(getSDLCBudgetF1) / this.state.allSectionTotalF1);
        let sp = sprintMatrix;
        let spF1 = sprintMatrixF1;
        spTaskTotal = spTaskTotal + sp;
        spTaskTotalF1 = spTaskTotalF1 + spF1;
        //  sprintMatrixState=sprintMatrix
      })
    })
    let capacityValue = parseFloat(e.target.value.slice(0,8))
    this.setState({ sprintMatrixState: capacityValue, sprintValue: (spTaskTotal / capacityValue).toFixed(2) })
  }
  onChangeSprint = (e) => {
    e.target.value = parseFloat(e.target.value) ? parseFloat(e.target.value.slice(0, 7)) : 0;
    let sprintMatrixState = 0
    let getSdlCTotal = this.state.displaySdlc.filter(itemD => itemD.activity === "BUDGETED TOTAL COST AFTER DISCOUNT (A + B - C)")[0];
    let getSDLCBudget = getSdlCTotal.budgeted;
    let getSDLCBudgetF1 = getSdlCTotal.fOneBudgeted;
    let spTaskTotal = 0;
    let spTaskTotalF1 = 0;
    this.state.data.map(item => {
      item.taskModel.map(el => {
        let sprintMatrix = (el.taskTotal) * this.state.storyPoint * (parseInt(getSDLCBudget) / this.state.allSectionTotal);
        let sprintMatrixF1 = el.fOneTaskTotal * this.state.storyPoint * (parseInt(getSDLCBudgetF1) / this.state.allSectionTotalF1);
        let sp = sprintMatrix;
        let spF1 = sprintMatrixF1;
        spTaskTotal = spTaskTotal + sp;
        spTaskTotalF1 = spTaskTotalF1 + spF1;
        //  sprintMatrixState=sprintMatrix
      })
    })
    console.log(e.target.value, sprintMatrixState)
    let requiredSprints = parseFloat(e.target.value.slice(0, 7))
    this.setState({ sprintValue: requiredSprints, sprintMatrixState: (spTaskTotal / requiredSprints).toFixed(2) })
  }
  onChangeSelectedSprintF1 = (e, props) => {
    let filterTask = this.state.sprintMatrixDataF1.map(item => {
      if (props.id == item.id) {
        item.sprints && item.sprints.map(s => {
          if (s.id == e.target.id) {
            s.value = !s.value
          }
        })
      }
      return item;
    })
    console.log("filterTask F1", filterTask)
    this.setState({ sprintMatrixDataF1: filterTask })
  }
  onChangeSelectedSprint = (e, props) => {
    console.log(e.target.id, e.target.value, props)
    let filterTask = this.state.sprintMatrixData.map(item => {
      if (props.id == item.id) {
        item.sprints && item.sprints.map(s => {
          if (s.id == e.target.id) {
            s.value = !s.value
          }
        })
      }
      return item;
    })
    console.log('filterTask', filterTask)
    this.setState({ sprintMatrixData: filterTask })

  }

  generateSprintsFunction = () => {
    this.setState({ showModal: false })
    let filterTask = [] //For all outcomes
    let filterTaskF1 = []
    let getSdlCTotal = this.state.displaySdlc.filter(itemD => itemD.activity === "BUDGETED TOTAL COST AFTER DISCOUNT (A + B - C)")[0];
    let getSDLCBudget = getSdlCTotal.budgeted;
    let getSDLCBudgetF1 = getSdlCTotal.fOneBudgeted;
    let spTaskTotal = 0;
    let spTaskTotalF1 = 0;
    //let taskArray = [];

    if (this.state.data.length > 0) {
      this.state.data.map(item => {
        item.taskModel.map(el => {
          let sprintMatrix = (el.taskTotal) * this.state.storyPoint * (parseInt(getSDLCBudget) / this.state.allSectionTotal);
          let sprintMatrixF1 = el.fOneTaskTotal * this.state.storyPoint * (parseInt(getSDLCBudgetF1) / this.state.allSectionTotalF1);
          let sp = sprintMatrix;
          let spF1 = sprintMatrixF1;
          spTaskTotal = spTaskTotal + sp;
          spTaskTotalF1 = spTaskTotalF1 + spF1;

          let remainingMatrix = Math.ceil(sprintMatrix / this.state.sprintMatrixState) //6
          remainingMatrix = remainingMatrix * this.state.sprintMatrixState; //2400
        //  taskArray.push(Math.ceil(sprintMatrix))
          filterTask.push({ ...el, sprintMatrix: Math.ceil(sprintMatrix), remainingMatrix: remainingMatrix - sprintMatrix })
          filterTaskF1.push({ ...el, sprintMatrixF1: Math.ceil(sprintMatrixF1) })
          return el
        })
      })
      // let taskMax = Math.max(...taskArray) + 2
      // this.setState({ taskMax: taskMax })


      //===============================================================
      //calculate How many sprint should be create.
      let rem = Math.round(((spTaskTotal / this.state.sprintMatrixState) + Number.EPSILON) * 100) / 100;
      let sprintArray = []
      //Generate sprint for Normal Sprints
      let frem = Math.ceil(rem)
      for (let i = 1; i <= frem; i++) {
        sprintArray.push('S' + i)
      }

      let sprintTableData = filterTask.map((item, idx) => {
        let s = [];
        delete item.auditModel;
        let o = Object.assign(item, idx, {});

        sprintArray.map(sp => {
          s.push({ id: sp, value: false });
        })

        o.sprints = s;

        item.id = "row" + idx;
        return o
      })

      let countChecked = 0;
      let sCapacity = parseFloat(this.state.sprintMatrixState);//sprint capacity
      let valueAdded = 0;
      let capacityCheck =0;
      let spremainingSMC =0;  
      let spCapAvail = 0;
      sprintTableData = sprintTableData.map((item, idx) => {
        let count = 0;
        let sumtworow = 0;  
        let taskAllocation = 0
        for (let i = countChecked; i < sprintArray.length; i++) {
          let d = sCapacity * count;
          
          if(taskAllocation >= parseFloat(sprintTableData[idx].sprintMatrix)){
            continue;
          }
          count = count + 1;
          let len = sprintTableData.length - 1;
         
           if (idx <=len) {
            sumtworow = sprintTableData[idx].sprintMatrix;
            if(valueAdded == 0 && spremainingSMC > 0)
            {
              sumtworow = spremainingSMC;              
            }
            valueAdded += sumtworow;
            if(valueAdded > sCapacity)
            {
              if(spCapAvail != 0 && spCapAvail < sCapacity){
                spremainingSMC = Math.round(((valueAdded - spCapAvail) + Number.EPSILON) * 100) / 100;   
              }
              else{
                spremainingSMC = Math.round(((valueAdded - sCapacity) + Number.EPSILON) * 100) / 100;                 
              }              
              capacityCheck = Math.round(((valueAdded - spremainingSMC) + Number.EPSILON) * 100) / 100;
              valueAdded = Math.round(((valueAdded - spremainingSMC) + Number.EPSILON) * 100) / 100;
            }   
            if (valueAdded < sCapacity) {
              item.sprints[i].value = true;
              taskAllocation = taskAllocation + valueAdded;          
              countChecked = i; 
              
              if(spCapAvail == 0){
                spremainingSMC = 0;   
                spCapAvail = Math.round(((sCapacity - valueAdded) + Number.EPSILON) * 100) / 100; 
                valueAdded = 0;
                return item;
              }                
              spCapAvail = 0;
              valueAdded = 0;                                                   
            }  
            else if(valueAdded == sCapacity)
            {
              item.sprints[i].value = true;
              taskAllocation = taskAllocation + valueAdded;
              valueAdded = 0;
              if (idx != 0) {
                idx = idx - 1;
              }
             countChecked = i + 1;
             spCapAvail = 0;
            }           
            else {         
              valueAdded = 0;
              if (idx != 0) {
                idx = idx - 1;
              }
            countChecked = i + 1;
            }
          }
       
          else {
            item.sprints[i].value = true;
          }
        }
        console.log(item);
        return item
      })

      ///======================
      sprintArray = [...new Set(sprintArray)]
      let autoGenerateCol = [];
      sprintArray && sprintArray.map((c, i) => {
        autoGenerateCol.push({
          field: c,
          prop: c,
          title: c.toUpperCase(),
          align: "right",
          cell: (props) => {
            return <input type="checkbox" className="form-control checkbox" onChange={(e) => this.onChangeSelectedSprint(e, props, false)} checked={props.sprints[i].value} id={props.sprints[i].id} />
          }
        })
      })


      //================================================================

      //Generate sprint for F1 Sprints
      let sprintArrayF1 = [];
      let remF1 = spTaskTotalF1 / this.state.sprintMatrixState;


      for (let i = 1; i <= Math.ceil(remF1); i++) {
        sprintArrayF1.push('S' + i)
      }
      //For F1 Sprint
      sprintArrayF1 = [...new Set(sprintArrayF1)];
      let autoGenerateColF1 = [];
      sprintArrayF1 && sprintArrayF1.map((c, i) => {
        autoGenerateColF1.push({
          field: c,
          prop: c,
          title: c.toUpperCase(),
          align: "right",
          cell: (props) => {
            return <input type="checkbox" className="form-control checkbox" onChange={(e) => this.onChangeSelectedSprintF1(e, props, true)} checked={props.sprints[i].value} id={props.sprints[i].id} />
          }
        })
      })

      let sprintTableDataF1 = filterTaskF1.map((item, idx) => {
        let sf1 = []
        delete item.auditModel;
        let o = Object.assign(item, {})
        sprintArrayF1.map(sp => {
          sf1.push({ id: sp, value: false })
        })
        o.sprints = sf1;
        item.id = "rowF1" + idx;
        return o
      })


    let countCheckedF1 = 0;
     
      let valueAddedF1 = 0;
      let capacityCheckF1 =0;
      let spremainingSMCF1 =0;  
      sprintTableDataF1 = sprintTableDataF1.map((item, idx) => {
        let count = 0;
        let sumtworow = 0;  
        for (let i = countCheckedF1; i < sprintArrayF1.length; i++) {
          let d = sCapacity * count;
          count = count + 1;
          let len = sprintTableDataF1.length - 1;
         
           if (idx <=len) {
            sumtworow = sprintTableDataF1[idx].sprintMatrixF1;
            if(valueAddedF1 == 0 && spremainingSMCF1 > 0)
            {
              sumtworow = spremainingSMCF1;              
            }
            valueAddedF1 += sumtworow;
            if(valueAddedF1 > sCapacity)
            {
              spremainingSMCF1 = valueAddedF1 - sCapacity;
             // capacityCheckF1 = valueAddedF1 - spremainingSMCF1;
              valueAddedF1 = valueAddedF1 - spremainingSMCF1;
            }   
            if (valueAddedF1 < sCapacity) {
              item.sprints[i].value = true;          
              countCheckedF1 = i;          
              spremainingSMCF1 = 0;   
              return item;
            }  
            else if(valueAddedF1 == sCapacity)
            {
              item.sprints[i].value = true;
              valueAddedF1 = 0;
              if (idx != 0) {
                idx = idx - 1;
              }
             countCheckedF1 = i + 1;
            }           
            else {         
              valueAddedF1 = 0;
              if (idx != 0) {
                idx = idx - 1;
              }
            countCheckedF1 = i + 1;
            }
          }
       
          else {
            item.sprints[i].value = true;
          }
        }
        console.log(item);
        return item
      })
      
      //============================================================
      //console.log("sprintTableData", sprintTableData, autoGenerateCol)
     
        this.setState({
          sprintMatrixData: sprintTableData,
          sprintMatrixDataF1: sprintTableDataF1,
          sprintColumns: [{
            field: "taskName",
            prop: "taskName",
            title: "User Story",
  
          },
          {
            field: "sprintMatrix",
            prop: "sprintMatrix",
            title: "Sprint Matrix",
            cell: (props) => {
              return Math.round(props.sprintMatrix)
            }
          },
  
          ...autoGenerateCol],
          sprintColumnsF1: [{
            field: "taskName",
            prop: "taskName",
            title: "User Story",
  
          },
          {
            field: "sprintMatrixF1",
            prop: "sprintMatrixF1",
            title: "Sprint Matrix F1",
            cell: (props) => {
              return Math.round(props.sprintMatrixF1)
            }
          },
          ...autoGenerateColF1]
        }, ()=>{
          this.initCustomizedCapacities(this.state.sprintMatrixState, this.state.sprintColumns)
        })
        
      
     
    } else {
      this.setState({ showSprintMatrixAlert: true })
    }
  }

  initCustomizedCapacities = (capacity, sprintColumns) =>{
    let initialCustomizedCapacity = {}
    console.log(sprintColumns)
      for(const col of sprintColumns){
        if(col.field == "taskName" || col.field == "sprintMatrix"){
          continue
        }
        initialCustomizedCapacity[col.field] = capacity;
      }
      console.log(initialCustomizedCapacity, "SSSSSSSSSSSSSSSSSS")
      this.setState({customizedSprintCapacity: initialCustomizedCapacity})
  }

  onChangeCustomizedCapacity = (e, key, index) =>{
    console.log("changing capacity::::::::", e.target.value)
    this.setState({customizedSprintCapacity : {...this.state.customizedSprintCapacity, [key]: e.target.value}}, ()=>{
      let newCustomCaps = {...this.state.customizedSprintCapacity};      
      for(const [i, col] of Object.keys(this.state.customizedSprintCapacity).entries()){
        console.log(i, index-2, col, parseInt(this.state.sprintMatrixState), (parseInt(this.state.sprintMatrixState) - parseInt(this.state.customizedSprintCapacity[key])), (Object.keys(this.state.customizedSprintCapacity).length - index + 1))
          if(i > index-2){
            newCustomCaps[col] = parseInt(parseInt(this.state.sprintMatrixState) + ((parseInt(this.state.sprintMatrixState) - parseInt(this.state.customizedSprintCapacity[key]))/(Object.keys(this.state.customizedSprintCapacity).length - index + 1)))
          }
      }
      console.log("Customized", newCustomCaps)
      this.setState({customizedSprintCapacity: newCustomCaps})
    })
  } 

  generateSprintMatrix = () => {
     let getSdlCTotal = this.state.displaySdlc.filter(itemD => itemD.activity === "BUDGETED TOTAL COST AFTER DISCOUNT (A + B - C)")[0];
      let getSDLCBudget = getSdlCTotal.budgeted;
      let taskArray = [];
      this.state.data.length > 0 && this.state.data.map(item => {
          item.taskModel.map(el => {
            let sprintMatrix = (el.taskTotal) * this.state.storyPoint * (parseInt(getSDLCBudget) / this.state.allSectionTotal);
  
            taskArray.push(Math.ceil(sprintMatrix))
            
            
          })
        })
        let taskMax = Math.max(...taskArray) + 2
        this.setState({ taskMax: taskMax })
  
    let mode = localStorage.getItem('mode');
//     if(this.state.sprintMatrixState>taskMax){
//       if (mode == "edit" && this.state.sprintMatrixData.length > 0) {
     
//         this.setState({ showModal: true })
       
//       } else {
//           this.generateSprintsFunction()
       
//       }
//     }else{
//  this.setState({showCapacityAlert:true,showCapacityAlertMsg:"Sprint capacity should be greater than "+taskMax,taskMax:taskMax})
//     }
    this.generateSprintsFunction()
  }
  render() {

    return (
      
      <div className="main-container" id="estimate">
        
        <form>
          <Header history={this.props.history} />
          <Grid container >

            <Snackbar open={this.state.showSaveMsg} autoHideDuration={1000} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })}><Alert severity={this.state.msgType} >{this.state.message}</Alert></Snackbar>
            <Snackbar open={this.state.showCapacityAlert} autoHideDuration={1500} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })}><Alert severity={"error"} >{this.state.showCapacityAlertMsg}</Alert></Snackbar>
            <Snackbar open={this.state.showSprintMatrixAlert} autoHideDuration={1000} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })}>
              <Alert severity={"error"} >Add stories and save it's BUILD story point in "Development Efforts" tab</Alert>
            </Snackbar>

            <ModalPopup
              show={this.state.showModal}
              onHide={() => this.setState({ showModal: false })}
              size={"sm"}                                       // "lg","sm" or "xl"
              centered={true}                                   // vertically center the Dialog in the window
              modelHeader={"Sprint Generate!"}                     // to show model header
              modelContent={
                <div className="footerButtons">
                  <p>Sprint matrix will reset, do you want to proceed? </p>
                  <Button onClick={() => {
                    this.generateSprintsFunction()
                    //this.setState({ showModal: false })
                  }}>Yes</Button>
                  <Button onClick={() => {

                    this.setState({ showModal: false })
                  }}>No</Button>
                </div>}             // to show model content

              backdrop={'static'}                               // true, false or 'static'

            />
            <Grid item md={12} spacing={2}>
              <TabPanel
                onSelect={this.onSelect}
                activeTab={this.state.key}
                tabData={[
                  {
                    eventKey: 'summarytab',
                    title: 'SUMMARY',
                    content:
                      <Summary
                        history={this.props.history}
                        onSubmit={this.onSubmit}
                        summaryFieldChange={this.summaryFieldChange}
                        onProjectandClientChange={this.onProjectandClientChange}
                        onBlurName={this.props.onBlurName}
                        projectName={this.state.projectName}
                        clientName={this.state.clientName}
                        allSectionTotal={this.state.allSectionTotal}
                        allSectionTotalF1={this.state.allSectionTotalF1}
                        allSectionStoryPoint={this.state.storyPoint}
                        storyPointChange={this.storyPointChange}
                        chartData={this.state.chartData}
                        chartDataF1={this.state.chartDataF1}
                        f1TotalPercentReduce={this.state.f1TotalPercentReduce}
                        showMsg={this.state.showMsg}
                        sizing={this.state.sizing}
                        questionnaire={this.state.questionnaire}
                        estimationMetadata={this.state.estimationMetadata}
                        rowUpdate={this.state.rowUpdate}
                        rowUpdate1={this.state.rowUpdate1}
                        rowUpdate2={this.state.rowUpdate2}
                        clientMsg={this.state.clientMsg}
                        projectMsg={this.state.projectMsg}
                        autoSaveOnBlur={this.autoSaveOnBlur}
                      />
                  },
                  {
                    eventKey: 'assumptions',
                    title: 'ASSUMPTIONS',
                    content:
                      <Assumptions
                        history={this.props.history}
                        onSubmit={this.onSubmit}
                        onProjectandClientChange={this.onProjectandClientChange}
                        assumptionFieldChange={this.assumptionFieldChange}
                        projectName={this.state.projectName}
                        clientName={this.state.clientName}
                        allSectionTotal={this.state.allSectionTotal}
                        allSectionTotalF1={this.state.allSectionTotalF1}
                        allSectionStoryPoint={this.state.storyPoint}
                        storyPointChange={this.storyPointChange}
                        chartData={this.state.chartData}
                        chartDataF1={this.state.chartDataF1}
                        f1TotalPercentReduce={this.state.f1TotalPercentReduce}
                        showMsg={this.state.showMsg} 
                        autoSaveOnBlur={this.autoSaveOnBlur}/>
                  },
                  {
                    eventKey: 'developementEstimate',
                    title: 'BUILD EFFORTS',
                    content:
                      <TaskViewer
                        history={this.props.history}
                        data={this.state.data}
                        onSubmit={this.onSubmit}
                        onProjectandClientChange={this.onProjectandClientChange}
                        projectName={this.state.projectName}
                        clientName={this.state.clientName}
                        showMsg={this.state.showMsg}
                        sectionOnchange={this.sectionOnchange}
                        taskOnchange={this.taskOnchange}
                        // taskChangeCheck={this.taskChangeCheck}
                        allSectionTotal={this.state.allSectionTotal}
                        allSectionTotalF1={this.state.allSectionTotalF1}
                        allSectionStoryPoint={this.state.storyPoint}
                        storyPointChange={this.storyPointChange}
                        chartData={this.state.chartData}
                        chartDataF1={this.state.chartDataF1}
                        addSection={this.addSection}
                        addTask={this.addTask}
                        onDeleteSection={this.onDeleteSection}
                        onDeleteTask={this.onDeleteTask}
                        onDropTask={this.onDropTask}
                        onDrop={this.onDrop}
                        f1TotalPercentReduce={this.state.f1TotalPercentReduce}
                        autoSaveOnBlur={this.autoSaveOnBlur}
                      />
                  },
                  {
                    eventKey: 'sdlc',
                    title: 'SDLC EFFORTS',
                    content:
                      <SDLC
                        history={this.props.history}
                        onSubmit={this.onSubmit}
                        onProjectandClientChange={this.onProjectandClientChange}
                        projectName={this.state.projectName}
                        clientName={this.state.clientName}
                        showMsg={this.state.showMsg}
                        displaySdlc={this.state.displaySdlc}
                        fieldChange={this.fieldChange}
                        allSectionTotal={this.state.allSectionTotal}
                        allSectionTotalF1={this.state.allSectionTotalF1}
                        allSectionStoryPoint={this.state.storyPoint}
                        storyPointChange={this.storyPointChange}
                        chartData={this.state.chartData}
                        chartDataF1={this.state.chartDataF1}
                        f1TotalPercentReduce={this.state.f1TotalPercentReduce}
                        autoSaveOnBlur={this.autoSaveOnBlur}
                        uniqueShareableLink={this.state.uniqueShareableLink}
                      />
                  },
                  {
                    eventKey: 'sprintMatrix',
                    title: 'SPRINT MATRIX',
                    content:
                      <SprintMatrix
                        history={this.props.history}
                        sprintMatrixData={this.state.sprintMatrixData}
                        sprintMatrixDataF1={this.state.sprintMatrixDataF1}
                        onSubmit={this.onSubmit}
                        onProjectandClientChange={this.onProjectandClientChange}
                        projectName={this.state.projectName}
                        clientName={this.state.clientName}
                        showMsg={this.state.showMsg}
                        onChangeCollaborator={this.onChangeCollaborator}
                        collaboratorValue={this.state.collaboratorValue}
                        allSectionTotal={this.state.allSectionTotal}
                        allSectionTotalF1={this.state.allSectionTotalF1}
                        onChangeMatrix={this.onChangeMatrix}
                        onChangeSprint={this.onChangeSprint}
                        sprintValue={this.state.sprintValue}
                        generateSprintMatrix={this.generateSprintMatrix}
                        allSectionStoryPoint={this.state.storyPoint}
                        sprintColumns={this.state.sprintColumns}
                        sprintColumnsF1={this.state.sprintColumnsF1}
                        sprintMatrixState={this.state.sprintMatrixState}
                        showCapacityAlert={this.state.showCapacityAlert}
                        customizedSprintCapacity={this.state.customizedSprintCapacity}
                        onChangeCustomizedCapacity={this.onChangeCustomizedCapacity}
                      />
                  },
                  {
                    eventKey: 'collaborators',
                    title: 'COLLABORATORS',
                    content:
                      <Collaborator
                        history={this.props.history}
                        onSubmit={this.onSubmit}
                        onProjectandClientChange={this.onProjectandClientChange}
                        projectName={this.state.projectName}
                        clientName={this.state.clientName}
                        showMsg={this.state.showMsg}
                        onChangeCollaborator={this.onChangeCollaborator}
                        collaboratorValue={this.state.collaboratorValue}
                      />
                  }
                ]}
                tabDesign={"material"}
              />

            </Grid>

          </Grid>
          {/* <button className="btnExport" onClick={this.exportToExcel}><GetAppIcon className="icnExport" /> Export to Excel</button>
      */}
        </form>

        <ModalPopup
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
          size={"lg"}                                       // "lg","sm" or "xl"
          centered={true}                                   // vertically center the Dialog in the window
          modelHeader={localStorage.getItem('mode') === 'edit' ? "Update Record" : "Save Record"}                     // to show model header
          modelContent={<p>{localStorage.getItem('mode') === 'edit' ? "Update" : "Save"} changes?</p>}             // to show model content
          modelFooter={<><Button onClick={this.showConfirmation}>YES</Button><Button onClick={() => this.setState({ modalShow: false })}>NO</Button></>}             //// to show model footer
          backdrop={'static'}                               // true, false or 'static'
          scrollable={true}                                 //boolean
          onEnter={this.onEnter}                            //Callback fired before the Modal transitions in
          onExit={this.onExit}                              //Callback fired right before the Modal transitions out
          closeButtonShow={true}
          parentClassName="ModalBackground"
          headerClassName="ModalHeaderBackground"
          bodyClassName="ModalBodyBackground"
          footerClassName="ModalFooterBackground"
        />

<Snackbar open={this.state.openDisclaimer} autoHideDuration={5000} onClose={() => this.handleCloseDisclaimer({ vertical: 'bottom', horizontal: 'right' })}>
              <Alert severity={"error"} > <h4>Disclaimer - Please save estimation frequently to avoid data loss!</h4></Alert>
            </Snackbar>

<Backdrop open={this.state.loading} style={{ zIndex: 1,
    color: '#fff',}}>
  <CircularProgress color="inherit" />
</Backdrop>

      </div>
    )
  }
}

export default Estimate;
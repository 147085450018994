import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./sdlc.scss";
import EstimateViewer from '../estimateViewer/estimateViewer';
import ChartViewer from '../charts/pieChart';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { get, post } from '../../api/api';
import { EDIT_ESTIMATE_BY_PROJECTID } from '../../api/baseURL';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import MatDatagrid from "f1-mat-data-grid-react";
import TextareaAutosize from 'react-textarea-autosize';
import ClientAndProductInput from '../clientAndProductInput/clientAndProductInput';
let total = 0;
let totalF1 = 0;
class SDLCViewer extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      alertText: "",
      rightOpen: true,
      totalA: "",
      columns: [
        { title: 'Phase', field: 'activity', sorting: false, editable: 'never', width: '40%' },
        {
          title: 'Phase Distribution w/o F1', field: 'reference', sorting: false, editable: 'never', width: '15%',
          render: (props) => {
            //console.log(props)


            return (props.id == "12" || props.id == "14" || props.id == "16" || props.id == "4" ? "" : <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">%</span>
              </div><input type="number"
                className={'form-control'}
                id={"reference" + props.id}
                value={this.state["reference" + props.id] || props.reference}
                defaultValue={props.reference}
                disabled={ true}
              >

              </input>
            </div>)
          }
        },
        {
          title: 'Budgeted Storypoint w/o F1', field: 'budgeted', sorting: false, editable: true, width: '15%',
          render: (props) => {
            return (props.id == "4" ? <input className="form-control"
              id={"budgeted" + props.id}
              disabled={ true}
              type="number"
              min={0}
              value={parseFloat(this.state["budgeted" + props.id]) ||parseFloat(props.budgeted)}
               ></input> : parseFloat(props.budgeted).toFixed(0))
          }
        },
        {
          title: 'Phase Distribution with F1', field: 'fOneRefrence', sorting: false, width: '15%', render: (props) => {
            //console.log(props)
            return (props.id == "12" || props.id == "14" || props.id == "16" || props.id == "4" ? "" :
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">%</span>
                </div><input type="number" className={'form-control'} id={"f1Reference" + props.id}
                  value={this.state["f1Reference" + props.id] || props.fOneRefrence} defaultValue={props.fOneRefrence}                   
                  disabled={ true}
                >
                </input>
              </div>)
          }
        },

        {
          title: 'Budgeted Storypoint with F1', field: 'fOneBudgeted',
          sorting: false, width: '15%',
          render: (props) => { return (props.id == "4" ? parseFloat(props.budgeted).toFixed(0) :  parseFloat( props.fOneBudgeted).toFixed(0)) }
        },

        {
          title: 'Justification', field: 'justification',
          sorting: false, width: '15%',
          render: (props) => { 
           return <TextareaAutosize  min={1} id={"justification"+props.id}  className={"form-control"} value={this.state["justification"+props.id ]||props.justification} defaultValue={ props.justification} />
           }
        }
      ]
    }
  }

  onProjectandClientChange = (e) => {
    
  }

  toggleSidebar = (event) => {
    let key = `${event.currentTarget.parentNode.id}Open`;
    this.setState({ [key]: !this.state[key] });
  }
  render() {
    let sdlc = JSON.stringify(this.state.displaySdlc)

    let rightOpen = this.state.rightOpen ? 'open' : 'closed';

    return (
      <form id="sdlc">
        <div id='layout'>
          <div className='content'>

            <Grid container spacing={3}>

              <Grid item xs={12} sm={12}>

                <div style={{ textAlign: "left" }} className="sdlc-table">

                  <div style={{ marginBottom: "5px" }}>
                    
                    <ClientAndProductInput
                      onProjectandClientChange={this.props.onProjectandClientChange}
                      projectName={this.props.projectName}
                      clientName={this.props.clientName}
                      disabled={true}
                    />
                  </div>
                  <div className="tabBox">
                    <MatDatagrid
                      rows={this.props.displaySdlc}
                      columns={this.state.columns}
                      title={" "}
                      search={false}
                      paging={false}
                      pageSize={16}
                      editable={{
                        editable: false,
                        showEdit: true,
                        showDelete: false,
                        showAdd: false
                      }}
                      actionsColumnIndex={-1}
                      rowUpdate={this.rowUpdate}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div id='right' >
            <div className='icon'
              onClick={this.toggleSidebar} >
              &equiv;
            </div>
            <div className={`sidebar ${rightOpen}`} >


              <EstimateViewer
              label="SDLC EFFORTS W/O F1"
                allSectionTotal={this.props.displaySdlc && this.props.displaySdlc[12] && this.props.displaySdlc[12].budgeted}
                allStoryPoint={parseInt(this.props.displaySdlc && this.props.displaySdlc[12] && this.props.displaySdlc[12].budgeted) * this.props.allSectionStoryPoint}
              />

              <h5 className="average-total total-block" style={{marginBottom:0,color:'#fff'}}><b>Using F1 Saves <span className="percentH">{this.props.f1TotalPercentReduce ? Math.round(this.props.f1TotalPercentReduce) : 0} %</span></b></h5>
              <EstimateViewer
              label="SDLC EFFORTS WITH F1"
                allSectionTotal={this.props.displaySdlc && this.props.displaySdlc[12] && this.props.displaySdlc[12].fOneBudgeted}
                allStoryPoint={parseFloat(parseFloat(this.props.displaySdlc && this.props.displaySdlc[12] && this.props.displaySdlc[12].fOneBudgeted) * this.props.allSectionStoryPoint).toFixed(0)}
              />


            </div>
          </div>

        </div>
      </form>
    )
  }
}

export default SDLCViewer;
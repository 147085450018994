import React from "react";
// import './login.css';
import '../../App.css';
//import DynamicForm from 'f1-dynamic-form-react'
import Header from '../header/loginHeader'
//import './landing.scss'

class Contacts extends React.Component{
constructor(){
    super()
}
    componentDidMount(){
        let colors = ['#87d5d473', '#ffd0976e', '#72c6fa6e', '#8000807d', "#28a74559","#dc35456e"];
        let selectedColor = colors[Math.floor(Math.random() * colors.length)]
        document.getElementsByTagName("body")[0].style.backgroundColor=selectedColor;
      }
      render(){
          return(
              <div align="center" className="landing main-container singleMidBox">
                  <Header history={this.props.history}/>
                  <h3>This application is powered by F1 Platform</h3>
                  <p>In case of any issue kindly go ahead and log on the below URL.</p>
                  <p>https://git.fulcrumww.com/FulcrumONE_Grp/FulcrumOne-Collaboration/issues</p>
                  </div>
          )
      }
}
export default Contacts;
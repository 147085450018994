import { Grid } from '@material-ui/core';
import React from 'react';

import { post } from '../../api/api';
import { CHANGE_PASSWORD } from '../../api/baseURL';

import {Link} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Header from '../header/header';
import DynamicForm from 'f1-dynamic-form-react';

import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

class ChangePassword extends React.Component{
    constructor(){
        super();
       this.state={
        current: { id: 1 },
        showMsg:false,
        message:"",
       
        formData: [
           
            { "key": "email", "type": "email", "disabled": false, "id": "email", "icon": "user", "label": "Email", "validators": [{ "name": "required", "value": true },{ "name": "isEmail", "value": true }] },
            
            { "key": "oldPassword", "type": "password", "disabled": false, "id": "password", "icon": "user", "label": "Old Password", "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 6 }, { "name": "maxLength", "value": 10, "validationMessage": "Maximum 10 digits allowed." }] },
            { "key": "newPassword", "type": "password", "disabled": false, "id": "password", "icon": "user", "label": "New Password", "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 6}, { "name": "maxLength", "value": 10, "validationMessage": "Maximum 10 digits allowed." }] },
           ]
      
        }

    }
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        this.setState({ showMsg: false })
    };
    submitForm = (event, value) => {

        //console.log("check",value,event);

        let obj = {
            "email": event.email,
          "oldPassword": event.oldPassword,
          "newPassword": event.newPassword
          }

if(event.email&&event.oldPassword&&event.newPassword){
        post(CHANGE_PASSWORD,obj).then(res => {
            this.setState({message:"Password changed successfully", showMsg:true})
            setTimeout(()=>{
              this.props.history.push("/")
            },500) 
        }).catch(err =>{
            // if(err.status == 400){
            //   this.setState({message:"Email already in use", showMsg:true})
            // }else{
        
            // }
        })
    }
    }
    render(){
        return(
            <div className="main-container homePage">
        <Header history={this.props.history} />
        <div className="analytics-section">
          <Grid container spacing={5}>
          <Grid item sm={4} md={4} >
              </Grid>
            <Grid item sm={4} md={4} >
              <Paper style={{textAlign:"left"}}>
              <DynamicForm
          key={this.state.current.id}
          className="form horizontal"
          title="Change Password"
          buttonSubmit="Submit"
          // defaultValues={this.state.current}
          model={this.state.formData}
          onSubmit={this.submitForm}
          disableSubmitOnInValid={false}
          customValidators={this.customValidators}
          displayInformation={true}
          
          design="bootstrap" // material/bootrstap/advanceMaterial/advanceBootstrap
          materialOutline={true}
          extErrors={this.state.extErrors}
          onChange={this.onChangeHandler}
        />
         {/* <p style={{textAlign:"center",padding:"5px",fontSize:"15px"}}><Link to="/">LOGIN HERE!</Link></p> */}
       
         <Snackbar open={this.state.showMsg} autoHideDuration={500} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })}><Alert severity="success" >{this.state.message}</Alert></Snackbar>
      
                  </Paper>
</Grid></Grid>            </div>
</div>
        )
    }
}
export default ChangePassword;
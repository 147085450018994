import React from "react";
import './header.scss'
import F1HeaderNav from 'f1-header-navigation-react'
import logo from '../../assets/img/logoWht.png'

class LoginHeader extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedProduct: 1,
    };
  }
  logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    this.props.history.push("/");
  }

  logoClick = () => {
    this.props.history.push("/home");
  }

  CustomBrand = ({}) => <div className="my-custom-brand">
  {/* <img src={logo} alt="" style={{width: '40px'}} />                 */}
</div>

onItemClick = (item, child_item) =>{
//console.log("Item Clicked!", item, child_item)
if(item.id==="register"){
  this.props.history.push('/register')
}
if(item.id==="login"){
  this.props.history.push('/')
  window.location.reload(true);
}
if(item.id==="contact"){
  this.props.history.push('/contact')
}
}

 brandImgStyle = {width: '90px'}
 navBarStyle = {backgroundColor: '#225cb2'}
 navBarSimpleStyle = {backgroundColor: '#A9A9A9'}


  headerConfig = {
    navBarStyle : this.navBarStyle,
    navBarClassName : 'my-custom-nav-bar',
    brandType : 'text', // text/image/component, required
    brandText: <span className="branding">
    <img className="mainLogo" src={logo}/>
    <b>FULCRUM ONE ESTIMATOR</b></span>,
   // brandLink: 'https://sb-react.fulcrumone.net/?path=/story/*',
    brandImgUrl: "", // in case of type === image
    brandClass: 'my-brand', // custom class for brand styling
    brandStyle: this.brandImgStyle, // style to be apply on brand component
    disableHambergerIcon: true, // default true
    onItemClick: this.onItemClick,
    menus:{
        right:[
       
          {
            type: 'link', // link/dropdown
            label: 'Contact',
            icon: '',
            href: '',
            childs: [], // in case of dropdowns
            is_active: true,
            className: 'contact',
            id:"contact"
          },
          {
            type: 'link', // link/dropdown
            label: 'Login',
            icon: '',
            href: '',
            childs: [], // in case of dropdowns
            is_active: false,
            className: 'login',
            id:"login"
          },
          // {
          //   type: 'link', // link/dropdown
          //   label: 'Register',
          //   icon: '',
          //   href: '',
          //   childs: [], // in case of dropdowns
          //   is_active: false,
          //   className: 'register',
          //   id:"register"
          // }
        ],
        // right:[
       
        //   {
        //     type: 'dropdown', // link/dropdown
        //     label: '',
        //     icon: 'user',
        //     className: 'custom-dropdown',         
        //     childs: [
        //       {
        //         label: 'Edit Profile',
        //         href: '/profile/',
        //         className: 'custom-option',
        //         icon: 'user'
        //       },
        //       {
        //         label: 'Logout',
        //         href: '/logout/',
        //         className: 'custom-option',
        //         icon: 'sign-out-alt'
        //       }
        //     ], // in case of dropdowns
        //     is_active: false,
        //   }
        // ]
      }
  }
  render() {
    return (

      <div>
           <F1HeaderNav
            config={this.headerConfig}
            brandComponent={<this.CustomBrand />} // in case of type === component
          />
       </div>
    );
  }
}

export default LoginHeader;

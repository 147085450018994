import { MsalAuthProvider, LoginType } from 'react-aad-msal';

export const authProvider = new MsalAuthProvider(
    {
        auth: {
            authority: "https://login.microsoftonline.com/7eb0ebdd-607d-46a7-b651-220d7b418537/",
            clientId:"fbfa6668-eb27-47ba-88ec-aa43f79ec0e6"
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: true,
        },
    },
    {
        scopes:['https://graph.microsoft.com/.default']
    },
    LoginType.Popup
);
export const authFetch = async url => {
    debugger;
    const token = await authProvider.getIdToken();
    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token.idToken.rawIdToken,
            'Content-Type': 'application/json',
        },
    });
};
import React from "react";
 import './login.scss';
import '../../App.css';
import Header from '../header/loginHeader';
import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
// import {Link} from 'react-router-dom'
// import {post,get} from '../../api/api'
// import {GENERATE_TOKEN,ESTIMATE_PROJECT_LIST} from '../../api/baseURL'
// import DynamicForm from 'f1-dynamic-form-react';


class Logout extends React.Component {
  
  componentDidMount(){
    localStorage.clear();
    sessionStorage.clear();
  }
  render() {
    return (
      <div className="main-container">
        <Header history={this.props.history}/>
          
        <Grid container justify="center" alignItems="left" class="singleMidBox" >
          <h3>Thank you!</h3>
        </Grid>
      
      </div>
    );
  }
}

export default Logout;

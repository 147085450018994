import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import EstimateViewer from '../estimateViewer/estimateViewer';
import ChartViewer from '../charts/pieChart';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { get, post } from '../../api/api';
import { EDIT_ESTIMATE_BY_PROJECTID } from '../../api/baseURL';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import MatDatagrid from "f1-mat-data-grid-react";
import ClientAndProductInput from '../../components/clientAndProductInput/clientAndProductInput'
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class Summary extends React.Component {
    constructor() {
        super();
        this.state = {
            show: false,
            alertText: "",
            rightOpen: true,
            data: [],
            allSectionTotal: 0,
            chartData: {
                labels: [
                    'Section1',
                    'Section2',

                ],
                datasets: [{
                    data: [0, 0],
                    backgroundColor: [
                        'turquoise',
                        'yellow',

                    ]
                }]
            },
            projectName: "",
            clientName: "",
            showMsg: false,
            estimationMetadataColumns: [
                { title: 'TITLE', field: 'key', editable: 'never', width: '30%' },
               // { title: 'DETAILS', field: 'value', sorting: true },
        { title: 'DETAILS', field: 'value', sorting: false,width: '70%',render:(props)=>{
          //console.log(props)
          return (<div key={props.id}>
            {/* {props.key=="Technology Platform"?
            <textarea  id={"value"+props.id} className={"form-control"} value={this.state["value"+props.id ]||props.value} defaultValue={ props.value} disabled={props.id==1?true:props.id==2?true:props.id==3?true:false} onChange={(e)=>this.summaryFieldChange(e,props.id,"value")} required  />: */}

            <input  type="text"  id={"value"+props.id} maxLength={props.key=="Technology Platform"?500:50} className={"form-control"} value={this.state["value"+props.id ]||props.value} defaultValue={ props.value} disabled={props.id==1?true:props.id==2?true:props.id==3?true:false} onChange={(e)=>this.summaryFieldChange(e,props.id,"value")} onBlur={this.props.autoSaveOnBlur} required  ></input>
           
        
          </div>)
        }}
                
            ],

            questionnaireColumns: [
                { title: 'TITLE', field: 'key', editable: 'never', width: '30%' },
                { title: 'DETAILS', field: 'value', width: '70%', sorting: true ,render:(props)=>{
                    //console.log(props)
                    return (<div>
                        {/* <select id={"questionnaire"+props.id} className={"form-control"} maxLength={50} value={this.state["questionnaire"+props.id ]||props.value} defaultValue={ props.value} onChange={(e)=>this.summaryFieldChange(e,props.id,"questionnaire")} required>
                            <option value="">Select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                            
                        </select> */}
                    
                        <FormControlLabel
        control={
          <Switch
          id={"questionnaire"+props.id}
            checked={this.state["questionnaire"+props.id]||props.value==='yes'?true:false}
           //defaultChecked={props.value==='yes'?true:false}
            onChange={(e)=>this.summaryFieldChange(e,props.id,"questionnaire")}
            onBlur={this.props.autoSaveOnBlur}
            name="checkedB"
            color="primary"
          />
        }
        label=    {this.state["questionnaire"+props.id ]||props.value ==="yes"?"Yes":"No"}
      />
                    </div>)}
                }
            ],
           
            columnsSizing: [
                { title: 'TITLE', field: 'key', editable: 'never', width: '30%' },
                { title: 'DETAILS', field: 'value', width: '70%', sorting: true,render:(props)=>{
                    //console.log(props)
                    return (<div>
                      <input  type="number" min={0} id={"sizing"+props.id} maxLength={50} className={"form-control"} value={this.state["sizing"+props.id ]||props.value} defaultValue={ props.value} onChange={(e)=>this.summaryFieldChange(e,props.id,"sizing")} onBlur={this.props.autoSaveOnBlur} required>
                       
                      </input>
                    </div>)} }
            ]
        }
    }
    getChartData = () => {

        let labels = [];
        let datasetsData = [];
        let color = [];
        let generateDataSet = this.state.data;
        generateDataSet && generateDataSet.map(item => {
            color.push(item.sectionColor)
            datasetsData.push(item.sectionTotal)
            labels.push(item.sectionName)
        })
        let totalData = {
            labels: labels,
            datasets: [{
                data: datasetsData,
                backgroundColor: color,
                hoverBackgroundColor: color
            }]
        }

        this.setState({ chartData: totalData })
    }

    componentDidMount() {
        let mode = localStorage.getItem("mode");
        let getTotal = localStorage.getItem("updatedTotal")

        if (mode === "edit") {
            let rowData = localStorage.getItem("rowData");
            let projectId = JSON.parse(rowData).projectId;

           
            get(EDIT_ESTIMATE_BY_PROJECTID + projectId).then(res => {
                //console.log(res, "response")
                if(res.status==200 && res.data){
                    let data=res.data;
                    localStorage.setItem("rowData",JSON.stringify(data))
                    this.setState({
                        clientName: data.clientName,
                        projectName: data.projectName
                    })
                }
               
               
                this.getChartData()
            }
            )

        } else {
            // this.setState({ data: taskViewer[0].sectionModel })

            this.getChartData()
        }


    }
    onBack = (e) => {
        e.preventDefault();
        this.props.history.push("/home")
    }

    onSave = (e) => {
        e.preventDefault(); 
            this.props.onSubmit()
    }
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ showMsg: false })
    };

 
    summaryFieldChange=(e,id,type)=>{
        //console.log("summaryFieldChange",e,id,type)
        this.props.summaryFieldChange(e,id,type)
    }
    
    toggleSidebar = (event) => {
        let key = `${event.currentTarget.parentNode.id}Open`;
        this.setState({ [key]: !this.state[key] });
      }
  
       


    render() {
        let summary = JSON.stringify(this.state.displaySdlc)
    
        let rightOpen = this.state.rightOpen ? 'open' : 'closed';
    return (
            <form id="summary">
                <div id='layout'>
          <div className='content'>
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={12}>
                        <div style={{ textAlign: "left" }} className="sdlc-table">

                            <div style={{ marginBottom: "5px"}}>
                                <Snackbar open={this.state.showMsg} autoHideDuration={6000} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })}><Alert severity="error" >Client Name, Project Name,Estimation Metadata, Questionnaire and Sizing are required fields!</Alert></Snackbar>

                                <ClientAndProductInput
                                    onProjectandClientChange={this.props.onProjectandClientChange}
                                    onBlurName={this.props.onBlurName}
                                    projectName={this.props.projectName}
                                    clientName={this.props.clientName}
                                    disabled={false}
                                    clientMsg={this.props.clientMsg}
                      projectMsg={this.props.projectMsg}
                      
                      
                                    /></div>
<div className="tabBox">
<Grid container spacing={3} className="summTble">
                            <Grid item xs={12} sm={12}>
                                <MatDatagrid
                                    rows={this.props.estimationMetadata}
                                    columns={this.state.estimationMetadataColumns}
                                    title={<span>ESTIMATION METADATA<span  style={{color:"red"}}>*</span></span>}
                                    rowUpdate={this.props.rowUpdate}
                                    onSelectionChange={this.onSelectionChange}
                                    search={false}
                                    editable={{
                                        editable: true,
                                        showEdit: false,
                                        showDelete: false,
                                        showAdd: false
                                    }}
                                    actionsColumnIndex={-1}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <MatDatagrid
                                    rows={this.props.questionnaire}
                                    columns={this.state.questionnaireColumns}
                                    title={<span>QUESTIONNAIRE<span  style={{color:"red"}}>*</span></span>}
                                    rowUpdate={this.props.rowUpdate1}
                                    onSelectionChange={this.onSelectionChange1}
                                    search={false}
                                    editable={{
                                        editable: true,
                                        showEdit: false,
                                        showDelete: false,
                                        showAdd: false
                                    }}
                                    actionsColumnIndex={-1}
                                />
                            </Grid>


                            <Grid item xs={12} sm={12}>
                                <MatDatagrid
                                    rows={this.props.sizing}
                                    columns={this.state.columnsSizing}
                                    title={<span>SIZING<span  style={{color:"red"}}>*</span></span>}
                                    rowUpdate={this.props.rowUpdate2}
                                    onSelectionChange={this.onSelectionChange}
                                    search={false}
                                    editable={{
                                        editable: true,
                                        showEdit: false,
                                        showDelete: false,
                                        showAdd: false
                                    }}
                                    actionsColumnIndex={-1}
                                />
                            </Grid>
                           
                           </Grid>
                            </div>
                        </div>
                        <div style={{ float: "right" }}>
                            <Button variant="contained" color="primary" onClick={this.onBack}>
                               Back to Dashboard
                            </Button>&nbsp;
                    <Button type="submit" variant="contained" color="primary" onClick={this.onSave}>
                                Save
                            </Button> &nbsp;

                            </div>
                    </Grid>
                    
                    </Grid>
                    </div>
                    <div id='right' >
            <div className='icon'
              onClick={this.toggleSidebar} >
              &equiv;
            </div>
            <div className={`sidebar ${rightOpen}`} >

            <EstimateViewer 
            allSectionTotal={this.props.allSectionTotal} 
            allStoryPoint={this.props.allSectionTotal*this.props.allSectionStoryPoint} 
            label="BUILD STORYPOINT W/O F1" renderView={"points"}/>

             <EstimateViewer allSectionTotal={this.props.allSectionTotalF1} allStoryPoint={this.props.allSectionTotalF1*this.props.allSectionStoryPoint}  label="BUILD STORYPOINT WITH F1" renderView={"points"}/>
            {/* <ChartViewer data={this.props.chartData} /> */}


            <EstimateViewer 
            allSectionTotal={this.props.allSectionTotal} 
            allStoryPoint={this.props.allSectionTotal*this.props.allSectionStoryPoint} 
            label="BUILD EFFORT W/O F1" renderView={"points&hours"}/>
            <h5 className="average-total total-block" style={{marginBottom:0,color:'#fff'}}><b>Using F1 Saves <span className="percentH">{this.props.f1TotalPercentReduce ?Math.round(this.props.f1TotalPercentReduce): 0} %</span></b></h5>
            <EstimateViewer allSectionTotal={this.props.allSectionTotalF1} allStoryPoint={this.props.allSectionTotalF1*this.props.allSectionStoryPoint}  label="BUILD EFFORT WITH F1" renderView={"points&hours"}/>
              


           </div>
          
           </div>
           </div>
           
            </form>
            
                
        )
    }
}

export default Summary;